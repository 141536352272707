<template>
  <div class="jingyin-center-preview">
    <!-- <headers></headers> -->
    <div class="tabs-box">
      <div class="tabs-left">
        <div>
          
          <div style="-aw-sdt-tag:''">
           
           
            <p
              style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:11pt"
            >
              <a href="#_Toc2709" style="text-decoration:none"
                ><span style="font-family:'仿宋'; color:#000000">一、</span
                ><span style="font-family:'仿宋'; color:#000000"
                  >项目简介</span
                ></a
              >
            </p>
            <p
              style="margin-top:0pt; margin-left:21pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:11pt"
            >
              <a href="#_Toc25143" style="text-decoration:none"
                ><span style="font-family:'仿宋'; color:#000000">（</span
                ><span style="font-family:'仿宋'; color:#000000">一</span
                ><span style="font-family:'仿宋'; color:#000000">）</span
                ><span style="font-family:'仿宋'; color:#000000"
                  >指导思想</span
                ></a
              >
            </p>
            <p
              style="margin-top:0pt; margin-left:42pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:11pt"
            >
              <a href="#_Toc15646" style="text-decoration:none"
                ><span style="font-family:'仿宋'; color:#000000">1.</span
                ><span style="font-family:'仿宋'; color:#000000"
                  >核心概念</span
                ></a
              >
            </p>
            <p
              style="margin-top:0pt; margin-left:42pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:11pt"
            >
              <a href="#_Toc18101" style="text-decoration:none"
                ><span style="font-family:'仿宋'; color:#000000">2.</span
                ><span style="font-family:'仿宋'; color:#000000"
                  >概念解读</span
                ></a
              >
            </p>
            <p
              style="margin-top:0pt; margin-left:21pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:11pt"
            >
              <a href="#_Toc23549" style="text-decoration:none"
                ><span style="font-family:'仿宋'; color:#000000">（</span
                ><span style="font-family:'仿宋'; color:#000000">二</span
                ><span style="font-family:'仿宋'; color:#000000"
                  >）项目目标</span
                ></a
              >
            </p>
            <p
              style="margin-top:0pt; margin-left:42pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:11pt"
            >
              <a href="#_Toc17196" style="text-decoration:none"
                ><span style="font-family:'仿宋'; color:#000000"
                  >1.项目总体目标</span
                ></a
              >
            </p>
            <p
              style="margin-top:0pt; margin-left:42pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:11pt"
            >
              <a href="#_Toc8440" style="text-decoration:none"
                ><span style="font-family:'仿宋'; color:#000000"
                  >2.三年阶段目标</span
                ></a
              >
            </p>
            <p
              style="margin-top:0pt; margin-left:21pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:11pt"
            >
              <a href="#_Toc29751" style="text-decoration:none"
                ><span style="font-family:'仿宋'; color:#000000">（</span
                ><span style="font-family:'仿宋'; color:#000000">三</span
                ><span style="font-family:'仿宋'; color:#000000"
                  >）实施对象</span
                ></a
              >
            </p>
            <p
              style="margin-top:0pt; margin-left:21pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:11pt"
            >
              <a href="#_Toc133" style="text-decoration:none"
                ><span style="font-family:'仿宋'; color:#000000">（</span
                ><span style="font-family:'仿宋'; color:#000000">四</span
                ><span style="font-family:'仿宋'; color:#000000"
                  >）实施方案</span
                ></a
              >
            </p>
            <p
              style="margin-top:0pt; margin-left:42pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:11pt"
            >
              <a href="#_Toc9647" style="text-decoration:none"
                ><span style="font-family:'仿宋'; color:#000000"
                  >1.调研阅读基础</span
                ></a
              >
            </p>
            <p
              style="margin-top:0pt; margin-left:42pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:11pt"
            >
              <a href="#_Toc867" style="text-decoration:none"
                ><span style="font-family:'仿宋'; color:#000000"
                  >2.培养阅读习惯</span
                ></a
              >
            </p>
            <p
              style="margin-top:0pt; margin-left:42pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:11pt"
            >
              <a href="#_Toc16476" style="text-decoration:none"
                ><span style="font-family:'仿宋'; color:#000000"
                  >3.匹配阅读资源</span
                ></a
              >
            </p>
            <p
              style="margin-top:0pt; margin-left:42pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:11pt"
            >
              <a href="#_Toc3619" style="text-decoration:none"
                ><span style="font-family:'仿宋'; color:#000000"
                  >4.营造阅读环境</span
                ></a
              >
            </p>
            <p
              style="margin-top:0pt; margin-left:42pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:11pt"
            >
              <a href="#_Toc16874" style="text-decoration:none"
                ><span style="font-family:'仿宋'; color:#000000"
                  >5.指导阅读方法</span
                ></a
              >
            </p>
            <p
              style="margin-top:0pt; margin-left:42pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:11pt"
            >
              <a href="#_Toc3504" style="text-decoration:none"
                ><span style="font-family:'仿宋'; color:#000000"
                  >6.展示阅读成果</span
                ></a
              >
            </p>
            <p
              style="margin-top:0pt; margin-left:21pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:11pt"
            >
              <a href="#_Toc31168" style="text-decoration:none"
                ><span style="font-family:'仿宋'; color:#000000">（</span
                ><span style="font-family:'仿宋'; color:#000000">五</span
                ><span style="font-family:'仿宋'; color:#000000"
                  >）项目特点</span
                ></a
              >
            </p>
            <p
              style="margin-top:0pt; margin-left:42pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:11pt"
            >
              <a href="#_Toc31062" style="text-decoration:none"
                ><span style="font-family:'仿宋'; color:#000000"
                  >1.专家引领，提高度</span
                ></a
              >
            </p>
            <p
              style="margin-top:0pt; margin-left:42pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:11pt"
            >
              <a href="#_Toc22902" style="text-decoration:none"
                ><span style="font-family:'仿宋'; color:#000000"
                  >2.听说读写，拓广度</span
                ></a
              >
            </p>
            <p
              style="margin-top:0pt; margin-left:42pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:11pt"
            >
              <a href="#_Toc24032" style="text-decoration:none"
                ><span style="font-family:'仿宋'; color:#000000"
                  >3.主题活动，挖深度</span
                ></a
              >
            </p>
            <p
              style="margin-top:0pt; margin-left:42pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:11pt"
            >
              <a href="#_Toc24049" style="text-decoration:none"
                ><span style="font-family:'仿宋'; color:#000000"
                  >4.阅读生态，有温度</span
                ></a
              >
            </p>
            <p
              style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:11pt"
            >
              <a href="#_Toc705" style="text-decoration:none"
                ><span style="font-family:'仿宋'; color:#000000">二、</span
                ><span style="font-family:'仿宋'; color:#000000"
                  >项目经费使用情况</span
                ></a
              >
            </p>
            <p
              style="margin-top:0pt; margin-left:21pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:11pt"
            >
              <a href="#_Toc32372" style="text-decoration:none"
                ><span style="font-family:'仿宋'; color:#000000">（</span
                ><span style="font-family:'仿宋'; color:#000000">一</span
                ><span style="font-family:'仿宋'; color:#000000"
                  >）财政资金到位率</span
                ></a
              >
            </p>
            <p
              style="margin-top:0pt; margin-left:21pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:11pt"
            >
              <a href="#_Toc358" style="text-decoration:none"
                ><span style="font-family:'仿宋'; color:#000000">（</span
                ><span style="font-family:'仿宋'; color:#000000">二</span
                ><span style="font-family:'仿宋'; color:#000000"
                  >）资金实际支出情况</span
                ></a
              >
            </p>
            <p
              style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:11pt"
            >
              <a href="#_Toc14628" style="text-decoration:none"
                ><span style="font-family:'仿宋'; color:#000000"
                  >三、项目组织实施情况</span
                ></a
              >
            </p>
            <p
              style="margin-top:0pt; margin-left:21pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:11pt"
            >
              <a href="#_Toc10265" style="text-decoration:none"
                ><span style="font-family:'仿宋'; color:#000000"
                  >（一）项目统筹规划</span
                ></a
              >
            </p>
            <p
              style="margin-top:0pt; margin-left:21pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:11pt"
            >
              <a href="#_Toc20697" style="text-decoration:none"
                ><span style="font-family:'仿宋'; color:#000000"
                  >（二）组建项目团队</span
                ></a
              >
            </p>
            <p
              style="margin-top:0pt; margin-left:21pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:11pt"
            >
              <a href="#_Toc3374" style="text-decoration:none"
                ><span style="font-family:'仿宋'; color:#000000"
                  >（三）明确研修内容</span
                ></a
              >
            </p>
            <p
              style="margin-top:0pt; margin-left:21pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:11pt"
            >
              <a href="#_Toc11815" style="text-decoration:none"
                ><span style="font-family:'仿宋'; color:#000000"
                  >（四）项目实施推进</span
                ></a
              >
            </p>
            <p
              style="margin-top:0pt; margin-left:42pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:11pt"
            >
              <a href="#_Toc7234" style="text-decoration:none"
                ><span style="font-family:'仿宋'; color:#000000"
                  >1.开展中小学阅读调查</span
                ></a
              >
            </p>
            <p
              style="margin-top:0pt; margin-left:42pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:11pt"
            >
              <a href="#_Toc9737" style="text-decoration:none"
                ><span style="font-family:'仿宋'; color:#000000"
                  >2.组织开展阅读系列活动</span
                ></a
              >
            </p>
            <p
              style="margin-top:0pt; margin-left:42pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:11pt"
            >
              <a href="#_Toc30429" style="text-decoration:none"
                ><span style="font-family:'仿宋'; color:#000000"
                  >3.整合优质阅读资源</span
                ></a
              >
            </p>
            <p
              style="margin-top:0pt; margin-left:42pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:11pt"
            >
              <a href="#_Toc14999" style="text-decoration:none"
                ><span style="font-family:'仿宋'; color:#000000"
                  >4.建设智能共读分享系统</span
                ></a
              >
            </p>
            <p
              style="margin-top:0pt; margin-left:42pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:11pt"
            >
              <a href="#_Toc2741" style="text-decoration:none"
                ><span style="font-family:'仿宋'; color:#000000"
                  >5.组织开展种子教师培养</span
                ></a
              >
            </p>
            <p
              style="margin-top:0pt; margin-left:42pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:11pt"
            >
              <a href="#_Toc20136" style="text-decoration:none"
                ><span style="font-family:'仿宋'; color:#000000"
                  >6.出版阅读成果</span
                ></a
              >
            </p>
            <p
              style="margin-top:0pt; margin-left:21pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:11pt"
            >
              <a href="#_Toc4955" style="text-decoration:none"
                ><span style="font-family:'仿宋'; color:#000000"
                  >（五）制定管理办法</span
                ></a
              >
            </p>
            <p
              style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:11pt"
            >
              <a href="#_Toc19283" style="text-decoration:none"
                ><span style="font-family:'仿宋'; color:#000000"
                  >四、项目完成情况</span
                ></a
              >
            </p>
            <p
              style="margin-top:0pt; margin-left:21pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:11pt"
            >
              <a href="#_Toc10789" style="text-decoration:none"
                ><span style="font-family:'仿宋'; color:#000000"
                  >（一）覆盖了银川一大批参与项目的一线教师和学生</span
                ></a
              >
            </p>
            <p
              style="margin-top:0pt; margin-left:21pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:11pt"
            >
              <a href="#_Toc28332" style="text-decoration:none"
                ><span style="font-family:'仿宋'; color:#000000"
                  >（二）生成了一系列具有银川市本地教育特色的学生作品</span
                ></a
              >
            </p>
            <p
              style="margin-top:0pt; margin-left:21pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:11pt"
            >
              <a href="#_Toc24207" style="text-decoration:none"
                ><span style="font-family:'仿宋'; color:#000000"
                  >（三）培养了一支素质优良、结构合理的未来卓越教师队伍</span
                ></a
              >
            </p>
            <p
              style="margin-top:0pt; margin-left:21pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:11pt"
            >
              <a href="#_Toc30000" style="text-decoration:none"
                ><span style="font-family:'仿宋'; color:#000000"
                  >（四）推进了一套家校共育的实践理念</span
                ></a
              >
            </p>
            <p
              style="margin-top:0pt; margin-left:21pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:11pt"
            >
              <a href="#_Toc25186" style="text-decoration:none"
                ><span style="font-family:'仿宋'; color:#000000"
                  >（五）实现“首都带首府、首府带县乡”的辐射互动</span
                ></a
              >
            </p>
            <p
              style="margin-top:0pt; margin-left:21pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:11pt"
            >
              <a href="#_Toc7095" style="text-decoration:none"
                ><span style="font-family:'仿宋'; color:#000000"
                  >（六）探索一套基于大数据的互联网+阅读的推广机制</span
                ></a
              >
            </p>
            <p
              style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:11pt"
            >
              <a href="#_Toc28777" style="text-decoration:none"
                ><span style="font-family:'仿宋'; color:#000000"
                  >五、项目效益</span
                ></a
              >
            </p>
            <p
              style="margin-top:0pt; margin-left:21pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:11pt"
            >
              <a href="#_Toc9711" style="text-decoration:none"
                ><span style="font-family:'仿宋'; color:#000000"
                  >（一）创新教育理念</span
                ></a
              >
            </p>
            <p
              style="margin-top:0pt; margin-left:21pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:11pt"
            >
              <a href="#_Toc2429" style="text-decoration:none"
                ><span style="font-family:'仿宋'; color:#000000"
                  >（二）以阅读为支点促进教育生态变革</span
                ></a
              >
            </p>
            <p
              style="margin-top:0pt; margin-left:21pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:11pt"
            >
              <a href="#_Toc13733" style="text-decoration:none"
                ><span style="font-family:'仿宋'; color:#000000"
                  >（三）构建家校学生三位一体的阅读生态</span
                ></a
              >
            </p>
            <p
              style="margin-top:0pt; margin-left:21pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:11pt"
            >
              <a href="#_Toc17190" style="text-decoration:none"
                ><span style="font-family:'仿宋'; color:#000000"
                  >（四）加强区域联动，辐射引领发展</span
                ></a
              >
            </p>
            <p
              style="margin-top:0pt; margin-left:21pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:11pt"
            >
              <a href="#_Toc24940" style="text-decoration:none"
                ><span style="font-family:'仿宋'; color:#000000"
                  >（五）项目培训满意度反馈</span
                ></a
              >
            </p>
            <p
              style="margin-top:0pt; margin-left:42pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:11pt"
            >
              <a href="#_Toc31789" style="text-decoration:none"
                ><span style="font-family:'仿宋'; color:#000000"
                  >1.项目整体满意度分析</span
                ></a
              >
            </p>
            <p
              style="margin-top:0pt; margin-left:42pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:11pt"
            >
              <a href="#_Toc11616" style="text-decoration:none"
                ><span style="font-family:'仿宋'; color:#000000"
                  >2.项目方案实施满意度分析</span
                ></a
              >
            </p>
            <p
              style="margin-top:0pt; margin-left:42pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:11pt"
            >
              <a href="#_Toc11983" style="text-decoration:none"
                ><span style="font-family:'仿宋'; color:#000000"
                  >3.项目策略方法满意度分析</span
                ></a
              >
            </p>
            <p
              style="margin-top:0pt; margin-left:42pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:11pt"
            >
              <a href="#_Toc24343" style="text-decoration:none"
                ><span style="font-family:'仿宋'; color:#000000"
                  >4.项目组织管理满意度分析</span
                ></a
              >
            </p>
            <p
              style="margin-top:0pt; margin-left:21pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:11pt"
            >
              <a href="#_Toc5489" style="text-decoration:none"
                ><span style="font-family:'仿宋'; color:#000000"
                  >（六）项目培训感言</span
                ></a
              >
            </p>
            <h1
              style="margin-top:17pt; margin-bottom:16.5pt; text-align:justify; page-break-inside:avoid; page-break-after:avoid; line-height:150%; widows:0; orphans:0; font-size:22pt"
            ></h1>
          </div>
        </div>
      </div>
      <div class="tabs-right">
        <div>
          <div style="-aw-headerfooter-type:linked">
            <p
              style="margin-top:0pt; margin-bottom:0pt; widows:0; orphans:0; padding:1pt 4pt; font-size:9pt"
            >
              <span style="font-family:'DejaVu Sans'">&#xa0;</span>
            </p>
          </div>
          <h1
            style="margin-top:17pt; margin-bottom:16.5pt; text-align:justify; page-break-inside:avoid; page-break-after:avoid; line-height:150%; widows:0; orphans:0; font-size:14pt"
          >
            <a name="_Toc2709"
              ><span style="font-family:'仿宋'">一、</span
              ><span style="font-family:'仿宋'">项目简介</span></a
            >
          </h1>
          <h2
            style="margin-top:13pt; margin-bottom:13pt; text-align:justify; page-break-inside:avoid; page-break-after:avoid; line-height:172%; widows:0; orphans:0; font-size:14pt"
          >
            <a name="_Toc25143"
              ><span style="font-family:'仿宋'">（</span
              ><span style="font-family:'仿宋'">一</span
              ><span style="font-family:'仿宋'">）</span
              ><span style="font-family:'仿宋'">指导思想</span></a
            >
          </h2>
          <h3
            style="margin-top:13pt; margin-bottom:13pt; text-align:justify; page-break-inside:avoid; page-break-after:avoid; line-height:172%; widows:0; orphans:0; font-size:14pt"
          >
            <a name="_Toc46321519"></a
            ><a name="_Toc15646"
              ><span style="font-family:'仿宋'">1.</span
              ><span style="font-family:'仿宋'">核心概念</span
              ><span style="-aw-bookmark-end:_Toc46321519"></span
            ></a>
          </h3>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28.2pt; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'"
              >京银互动一带一路共读工程，响应国家大力倡导的“全民阅读”的重要指示精神，以习近平新时代中国特色社会主义思想和党的十九大精神为指导，贯彻落实《银川回族自治区“互联网</span
            ><span style="font-family:'仿宋'"
              >+教育”示范区建设规划（2018年－2022年）》（宁政发﹝2018﹞49号）《银川回族自治区“互联网+教育”示范区建设实施方案》（宁政办发﹝2018﹞118号）《中共银川市委员会关于加快互联网数字经济发展的若干意见》工作部署，并依据《“首都带首府”京银“互联网+教育”战略合作框架协议》，结合京银两地工作实际，</span
            ><span style="font-family:'仿宋'"
              >聚焦银川市教育质量与内涵式发展，构建具有银川本地化特色的“中小学素养阅读生态区”。</span
            >
          </p>
          <h3
            style="margin-top:13pt; margin-bottom:13pt; text-align:justify; page-break-inside:avoid; page-break-after:avoid; line-height:172%; widows:0; orphans:0; font-size:14pt"
          >
            <a name="_Toc46321520"></a
            ><a name="_Toc18101"
              ><span style="font-family:'仿宋'">2.</span
              ><span style="font-family:'仿宋'">概念解读</span
              ><span style="-aw-bookmark-end:_Toc46321520"></span
            ></a>
          </h3>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28.2pt; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'"
              >京银互动一带一路共读工程以构建中小学素养阅读生态区为指导思想和工作目标</span
            ><span style="font-family:'仿宋'">，</span
            ><span style="font-family:'仿宋'">何为</span
            ><span style="font-family:'仿宋'"
              >“中小学素养阅读生态区”？具体解读如下：</span
            >
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28.2pt; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'">（1）素养阅读</span>
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; text-align:justify; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'">素养阅读</span
            ><span style="font-family:'仿宋'"
              >，其根本在“阅读”，即强调阅读行为本身，强调通过改进阅读品质，提升阅读方法，培养中小学生的阅读习惯，提高综合素养水平。素养阅读突出阅读行为对于中小学生发展综合素养养成的基础性、根本性和关键性地位。</span
            >
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28.2pt; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'">（2）素养阅读生态区</span>
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'"
              >生态是指一切生物的生存状态，以及它们之间和它与环境之间环环相扣的关系。素养阅读生态区强调以学生为主体，通过学生个体阅读带动家庭亲子阅读，辅以教师阅读指导、班级阅读氛围生成，不断扩大辐射到年级、学校和社区，营造书香家庭、书香校园、书香社区，从而建构以素养阅读为基础，关联教师、同伴、家长、学校、社区等，形成一个自发展的阅读生态闭环。</span
            >
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28.2pt; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'"
              >京银互动一带一路共读工程计划通过三年的时间建设银川中小学素养阅读生态区，促进银川基础教育质量优质均衡发展，促进每个学生全面发展，整体提升银川教育质量和教育水平。</span
            >
          </p>
          <h2
            style="margin-top:13pt; margin-bottom:13pt; text-align:justify; page-break-inside:avoid; page-break-after:avoid; line-height:172%; widows:0; orphans:0; font-size:14pt"
          >
            <a name="_Toc46321521"></a
            ><a name="_Toc23549"
              ><span style="font-family:'仿宋'">（</span
              ><span style="font-family:'仿宋'">二</span
              ><span style="font-family:'仿宋'">）项目目标</span
              ><span style="-aw-bookmark-end:_Toc46321521"></span
            ></a>
          </h2>
          <h3
            style="margin-top:13pt; margin-bottom:13pt; text-align:justify; page-break-inside:avoid; page-break-after:avoid; line-height:172%; widows:0; orphans:0; font-size:14pt"
          >
            <a name="_Toc17196"></a
            ><a name="_Toc46321522"
              ><span style="font-family:'仿宋'">1.项目总体目标</span
              ><span style="-aw-bookmark-end:_Toc17196"></span
            ></a>
          </h3>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; text-align:justify; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'"
              >京银互动一带一路共读工程，以构建中小学素养阅读生态区为目标，借助互联网大数据分析评价技术，有效激发学生阅读兴趣、提升学生阅读能力、丰富校园阅读内涵，在传统阅读促进活动基础上拓展创新，突出家校联动的指导作用，强化阅读在中小学课堂教学和课外阅读的互补价值，推动阅读的整体性变革。项目整合北京市优质教育资源，及首都师范大学的专家师范生等特色资源，以专家为学术引领，以学生为主体，以点带面，发挥辐射示范作用，在银川市中小学开展深层次校园素养阅读生态区的促进提升工作，探索银川教育的特色发展、优质发展和内涵发展模式，带动银川</span
            ><span style="font-family:'仿宋'"
              >教育质量的均衡发展和整体提升。</span
            ><span style="font-family:'仿宋'"
              >具体目标可概括为“三提升、三转变、三发展”：</span
            >
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; text-align:justify; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'">（</span
            ><span style="font-family:'仿宋'">1</span
            ><span style="font-family:'仿宋'">）</span
            ><span style="font-family:'仿宋'">实现</span
            ><span style="font-family:'仿宋'">项目</span
            ><span style="font-family:'仿宋'"
              >校的三个提升：创设阅读氛围，提升学校阅读环境；深化家校互动，提高阅读参与程度；整合阅读方式，提升全员阅读能力；</span
            >
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; text-align:justify; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'">（</span
            ><span style="font-family:'仿宋'">2</span
            ><span style="font-family:'仿宋'">）</span
            ><span style="font-family:'仿宋'">实现教师的三个转变：</span
            ><span style="font-family:'仿宋'">&#xa0;</span
            ><span style="font-family:'仿宋'">转变思维方式，让</span
            ><span style="font-family:'仿宋'">阅读教学真正地充满生机和活力</span
            ><span style="font-family:'仿宋'">；</span
            ><span style="font-family:'仿宋'"
              >转变阅读态度，将阅读发展成师生课内外成长的</span
            ><span style="font-family:'仿宋'">工具</span
            ><span style="font-family:'仿宋'">；转变品味意识，将阅读</span
            ><span style="font-family:'仿宋'">变</span
            ><span style="font-family:'仿宋'">为</span
            ><span style="font-family:'仿宋'">提升</span
            ><span style="font-family:'仿宋'">自我文化品位的途径</span
            ><span style="font-family:'仿宋'">；</span>
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; text-align:justify; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'">（</span
            ><span style="font-family:'仿宋'">3</span
            ><span style="font-family:'仿宋'">）</span
            ><span style="font-family:'仿宋'"
              >实现学生的三个发展：让阅读成为习惯，</span
            ><span style="font-family:'仿宋'">培养</span
            ><span style="font-family:'仿宋'">学生的阅读</span
            ><span style="font-family:'仿宋'">兴趣</span
            ><span style="font-family:'仿宋'">；让阅读成为</span
            ><span style="font-family:'仿宋'">手段</span
            ><span style="font-family:'仿宋'">，</span
            ><span style="font-family:'仿宋'">发展学生的阅读能力</span
            ><span style="font-family:'仿宋'"
              >；让阅读成为纽带，实现素养阅读生态区建设。</span
            >
          </p>
          <h3
            style="margin-top:13pt; margin-bottom:13pt; text-align:justify; page-break-inside:avoid; page-break-after:avoid; line-height:172%; widows:0; orphans:0; font-size:14pt"
          >
            <a name="_Toc8440"></a
            ><a name="_Toc46321523"
              ><span style="font-family:'仿宋'">2.三年阶段目标</span
              ><span style="-aw-bookmark-end:_Toc8440"></span
            ></a>
          </h3>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; text-align:justify; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'"
              >本工程将以三年为一个发展周期，将三年规划为三个层次，且每个层次对应一个学年时间，三年阶段目标如下：</span
            >
          </p>
          <table
            cellspacing="0"
            cellpadding="0"
            style="width:100%; border-collapse:collapse"
          >
            <tr style="height:24.3pt">
              <td
                style="width:5.96%; border-style:solid; border-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:middle; background-color:#d8d8d8"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; widows:0; orphans:0; font-size:12pt"
                >
                  <span style="font-family:'仿宋'; font-weight:bold">序号</span>
                </p>
              </td>
              <td
                style="width:17.02%; border-style:solid; border-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:middle; background-color:#d8d8d8"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; widows:0; orphans:0; font-size:12pt"
                >
                  <span style="font-family:'仿宋'; font-weight:bold">阶段</span>
                </p>
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; widows:0; orphans:0; font-size:12pt"
                >
                  <span style="font-family:'仿宋'; font-weight:bold">名称</span>
                </p>
              </td>
              <td
                style="width:77%; border-style:solid; border-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:middle; background-color:#d8d8d8"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; widows:0; orphans:0; font-size:12pt"
                >
                  <span style="font-family:'仿宋'; font-weight:bold"
                    >阶段性目标</span
                  >
                </p>
              </td>
            </tr>
            <tr>
              <td
                style="width:5.96%; border-style:solid; border-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; widows:0; orphans:0; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">1</span>
                </p>
              </td>
              <td
                style="width:17.02%; border-style:solid; border-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; widows:0; orphans:0; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">第一阶段</span>
                </p>
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; widows:0; orphans:0; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">（2020年）</span>
                </p>
              </td>
              <td
                style="width:77%; border-style:solid; border-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; line-height:150%; widows:0; orphans:0; font-size:12pt"
                >
                  <span style="font-family:'仿宋'"
                    >1.启动共读工程，拓展阅读理念和阅读形式，推动师生树立全科阅读理念，延伸阅读领域，整合阅读资源，建立线上线下阅读新生态；</span
                  >
                </p>
                <p
                  style="margin-top:0pt; margin-bottom:0pt; line-height:150%; widows:0; orphans:0; font-size:12pt"
                >
                  <span style="font-family:'仿宋'"
                    >2.创设阅读新机制，建设阅读资源及评价工具，为深层提升奠定基础；</span
                  >
                </p>
                <p
                  style="margin-top:0pt; margin-bottom:0pt; line-height:150%; widows:0; orphans:0; font-size:12pt"
                >
                  <span style="font-family:'仿宋'"
                    >3.以阅读环境、阅读资源、阅读活动、阅读培训为主线，发挥种子教师、学生和家庭的示范引领作用，逐步扩充共读工程辐射范围，增强阅读效益。</span
                  >
                </p>
              </td>
            </tr>
            <tr>
              <td
                style="width:5.96%; border-style:solid; border-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; widows:0; orphans:0; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">2</span>
                </p>
              </td>
              <td
                style="width:17.02%; border-style:solid; border-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; widows:0; orphans:0; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">第二阶段</span>
                </p>
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; widows:0; orphans:0; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">（2021年）</span>
                </p>
              </td>
              <td
                style="width:77%; border-style:solid; border-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; line-height:150%; widows:0; orphans:0; font-size:12pt"
                >
                  <span style="font-family:'仿宋'"
                    >1.进一步深化素养阅读生态区建设模式，拓展阅读阵地，推动课内外、校内外阅读有效结合，让阅读行动逐步扩充到每一个师生和家庭中，打造阅读示范典型；</span
                  >
                </p>
                <p
                  style="margin-top:0pt; margin-bottom:0pt; line-height:150%; widows:0; orphans:0; font-size:12pt"
                >
                  <span style="font-family:'仿宋'"
                    >2.推动阅读跨界合作，继续加强政府、社区和学校的合作，配合融媒体手段，</span
                  ><span style="font-family:'仿宋'"
                    >形成具有银川本地化特色的生态区建设模式，保障项目持续稳健发展；</span
                  >
                </p>
                <p
                  style="margin-top:0pt; margin-bottom:0pt; line-height:150%; widows:0; orphans:0; font-size:12pt"
                >
                  <span style="font-family:'仿宋'"
                    >3.通过师生共读、生生共读、亲子共读等方式带动全民阅读，全面倡导全科阅读、主题阅读、群文阅读等阅读理念，开展阅读团队、阅读课程和阅读阵地建设，不断引领广大师生、家长、社会各界人士参与</span
                  ><span style="font-family:'仿宋'"
                    >到阅读行动，促进书香银川建设，提升教育教学质量，提高公民素养。</span
                  >
                </p>
              </td>
            </tr>
            <tr>
              <td
                style="width:5.96%; border-style:solid; border-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; widows:0; orphans:0; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">3</span>
                </p>
              </td>
              <td
                style="width:17.02%; border-style:solid; border-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; widows:0; orphans:0; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">第三阶段</span>
                </p>
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; widows:0; orphans:0; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">（2022年）</span>
                </p>
              </td>
              <td
                style="width:77%; border-style:solid; border-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; line-height:150%; widows:0; orphans:0; font-size:12pt"
                >
                  <span style="font-family:'仿宋'"
                    >1.扩大共读工程的影响力，创城银川智慧阅读品牌，推动形成银川阅读传统项目；</span
                  >
                </p>
                <p
                  style="margin-top:0pt; margin-bottom:0pt; line-height:150%; widows:0; orphans:0; font-size:12pt"
                >
                  <span style="font-family:'仿宋'"
                    >2.以中小学校为基地，搭建政府、社会、家庭、学校“四位一体”的联动模式，整合各项教育资源，构建上下联动的阅读新机制，触发阅读自觉，不断增值人生。</span
                  >
                </p>
              </td>
            </tr>
          </table>

          <p
            style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"
          >
            <a name="_Toc46321524"
              ><span style="font-family:Calibri">&#xa0;</span></a
            >
          </p>
          <h2
            style="margin-top:13pt; margin-bottom:13pt; text-align:justify; page-break-inside:avoid; page-break-after:avoid; line-height:172%; widows:0; orphans:0; font-size:14pt"
          >
            <a name="_Toc29751"
              ><span style="font-family:'仿宋'">（</span
              ><span style="font-family:'仿宋'">三</span
              ><span style="font-family:'仿宋'">）实施对象</span
              ><span style="-aw-bookmark-end:_Toc46321524"></span
            ></a>
          </h2>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'">该实施方案</span
            ><span style="font-family:'仿宋'; color:#0d0d0d">以第一学</span
            ><span style="font-family:'仿宋'"
              >年方案详细展开。京银互动一带一路共读工程第一学年主要实施对象为银川市直属、三区两县一市遴选出的</span
            ><span style="font-family:'仿宋'">40所项目学校</span
            ><span style="font-family:'仿宋'">，具体学校详见下表：</span>
          </p>
          <div style="text-align:center">
            <table
              cellspacing="0"
              cellpadding="0"
              style="width:435.45pt; margin-right:auto; margin-left:auto; border-collapse:collapse"
            >
              <tr style="height:40.5pt">
                <td
                  style="border-style:solid; border-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:middle; background-color:#d8d8d8"
                >
                  <p
                    style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; widows:0; orphans:0; font-size:12pt"
                  >
                    <span style="font-family:'仿宋'; font-weight:bold"
                      >序号</span
                    >
                  </p>
                </td>
                <td
                  style="width:74.6pt; border-style:solid; border-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:middle; background-color:#d8d8d8"
                >
                  <p
                    style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; widows:0; orphans:0; font-size:12pt"
                  >
                    <span style="font-family:'仿宋'; font-weight:bold"
                      >市/县(区)</span
                    >
                  </p>
                </td>
                <td
                  style="width:39.8pt; border-style:solid; border-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:middle; background-color:#d8d8d8"
                >
                  <p
                    style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; widows:0; orphans:0; font-size:12pt"
                  >
                    <span style="font-family:'仿宋'; font-weight:bold"
                      >学段</span
                    >
                  </p>
                </td>
                <td
                  style="width:265.05pt; border-style:solid; border-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:middle; background-color:#d8d8d8"
                >
                  <p
                    style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; widows:0; orphans:0; font-size:12pt"
                  >
                    <span style="font-family:'仿宋'; font-weight:bold"
                      >学校</span
                    >
                  </p>
                </td>
              </tr>
              <tr style="height:44.25pt">
                <td
                  rowspan="2"
                  style="border-style:solid; border-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:middle"
                >
                  <p
                    style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; widows:0; orphans:0; font-size:12pt"
                  >
                    <span style="font-family:'仿宋'">1</span>
                  </p>
                </td>
                <td
                  rowspan="2"
                  style="width:74.6pt; border-style:solid; border-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:middle"
                >
                  <p
                    style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; widows:0; orphans:0; font-size:12pt"
                  >
                    <span style="font-family:'仿宋'"
                      >市直属及共同体发展学校</span
                    >
                  </p>
                  <p
                    style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; widows:0; orphans:0; font-size:12pt"
                  >
                    <span style="font-family:'仿宋'">（14所）</span>
                  </p>
                </td>
                <td
                  style="width:39.8pt; border-style:solid; border-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:middle"
                >
                  <p
                    style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; widows:0; orphans:0; font-size:12pt"
                  >
                    <span style="font-family:'仿宋'">初中         （7所）</span>
                  </p>
                </td>
                <td
                  style="width:265.05pt; border-style:solid; border-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:middle"
                >
                  <p
                    style="margin-top:0pt; margin-bottom:0pt; line-height:150%; widows:0; orphans:0; font-size:12pt"
                  >
                    <span style="font-family:'仿宋'"
                      >北塔中学、阅海一中、唐中西校区、唐中南校区、唐中宝湖校区、景城一中（种子）、宁东学校</span
                    >
                  </p>
                </td>
              </tr>
              <tr style="height:63.75pt">
                <td
                  style="width:39.8pt; border-style:solid; border-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:middle"
                >
                  <p
                    style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; widows:0; orphans:0; font-size:12pt"
                  >
                    <span style="font-family:'仿宋'">小学        （7所）</span>
                  </p>
                </td>
                <td
                  style="width:265.05pt; border-style:solid; border-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:middle"
                >
                  <p
                    style="margin-top:0pt; margin-bottom:0pt; line-height:150%; widows:0; orphans:0; font-size:12pt"
                  >
                    <span style="font-family:'仿宋'"
                      >银川市实验小学、阅海小学、阅海二小、阅海三小、阅海四小、滨河新区景城第一小学、博文小学</span
                    >
                  </p>
                </td>
              </tr>
              <tr style="height:37.5pt">
                <td
                  rowspan="2"
                  style="border-style:solid; border-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:middle"
                >
                  <p
                    style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; widows:0; orphans:0; font-size:12pt"
                  >
                    <span style="font-family:'仿宋'">2</span>
                  </p>
                </td>
                <td
                  rowspan="2"
                  style="width:74.6pt; border-style:solid; border-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:middle"
                >
                  <p
                    style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; widows:0; orphans:0; font-size:12pt"
                  >
                    <span style="font-family:'仿宋'">兴庆区</span>
                  </p>
                  <p
                    style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; widows:0; orphans:0; font-size:12pt"
                  >
                    <span style="font-family:'仿宋'">（5所）</span>
                  </p>
                </td>
                <td
                  style="width:39.8pt; border-style:solid; border-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:middle"
                >
                  <p
                    style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; widows:0; orphans:0; font-size:12pt"
                  >
                    <span style="font-family:'仿宋'">初中      （1所）</span>
                  </p>
                </td>
                <td
                  style="width:265.05pt; border-style:solid; border-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:middle"
                >
                  <p
                    style="margin-top:0pt; margin-bottom:0pt; line-height:150%; widows:0; orphans:0; font-size:12pt"
                  >
                    <span style="font-family:'仿宋'">银川十五中</span>
                  </p>
                </td>
              </tr>
              <tr style="height:39.75pt">
                <td
                  style="width:39.8pt; border-style:solid; border-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:middle"
                >
                  <p
                    style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; widows:0; orphans:0; font-size:12pt"
                  >
                    <span style="font-family:'仿宋'">小学         （4所）</span>
                  </p>
                </td>
                <td
                  style="width:265.05pt; border-style:solid; border-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:middle"
                >
                  <p
                    style="margin-top:0pt; margin-bottom:0pt; line-height:150%; widows:0; orphans:0; font-size:12pt"
                  >
                    <span style="font-family:'仿宋'"
                      >兴庆回民实验小学、兴庆区唐徕回民小学、兴庆回民一小、大塘中心小学（农村）</span
                    >
                  </p>
                </td>
              </tr>
              <tr style="height:32.25pt">
                <td
                  rowspan="2"
                  style="border-style:solid; border-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:middle"
                >
                  <p
                    style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; widows:0; orphans:0; font-size:12pt"
                  >
                    <span style="font-family:'仿宋'">3</span>
                  </p>
                </td>
                <td
                  rowspan="2"
                  style="width:74.6pt; border-style:solid; border-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:middle"
                >
                  <p
                    style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; widows:0; orphans:0; font-size:12pt"
                  >
                    <span style="font-family:'仿宋'">金凤区</span>
                  </p>
                  <p
                    style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; widows:0; orphans:0; font-size:12pt"
                  >
                    <span style="font-family:'仿宋'">（5所）</span>
                  </p>
                </td>
                <td
                  style="width:39.8pt; border-style:solid; border-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:middle"
                >
                  <p
                    style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; widows:0; orphans:0; font-size:12pt"
                  >
                    <span style="font-family:'仿宋'">初中      （1所）</span>
                  </p>
                </td>
                <td
                  style="width:265.05pt; border-style:solid; border-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:middle"
                >
                  <p
                    style="margin-top:0pt; margin-bottom:0pt; line-height:150%; widows:0; orphans:0; font-size:12pt"
                  >
                    <span style="font-family:'仿宋'">丰登中学（农村）</span>
                  </p>
                </td>
              </tr>
              <tr style="height:41.25pt">
                <td
                  style="width:39.8pt; border-style:solid; border-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:middle"
                >
                  <p
                    style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; widows:0; orphans:0; font-size:12pt"
                  >
                    <span style="font-family:'仿宋'">小学       （4所）</span>
                  </p>
                </td>
                <td
                  style="width:265.05pt; border-style:solid; border-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:middle"
                >
                  <p
                    style="margin-top:0pt; margin-bottom:0pt; line-height:150%; widows:0; orphans:0; font-size:12pt"
                  >
                    <span style="font-family:'仿宋'"
                      >金凤光彩小学（农村）、金凤良田小学（农村）、金凤区第四小学（市实验共同体）、金凤区第九小学（市实验共同体）</span
                    >
                  </p>
                </td>
              </tr>
              <tr style="height:32.25pt">
                <td
                  rowspan="2"
                  style="border-style:solid; border-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:middle"
                >
                  <p
                    style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; widows:0; orphans:0; font-size:12pt"
                  >
                    <span style="font-family:'仿宋'">4</span>
                  </p>
                </td>
                <td
                  rowspan="2"
                  style="width:74.6pt; border-style:solid; border-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:middle"
                >
                  <p
                    style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; widows:0; orphans:0; font-size:12pt"
                  >
                    <span style="font-family:'仿宋'">西夏区</span>
                  </p>
                  <p
                    style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; widows:0; orphans:0; font-size:12pt"
                  >
                    <span style="font-family:'仿宋'">（5所）</span>
                  </p>
                </td>
                <td
                  style="width:39.8pt; border-style:solid; border-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:middle"
                >
                  <p
                    style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; widows:0; orphans:0; font-size:12pt"
                  >
                    <span style="font-family:'仿宋'">初中      （1所）</span>
                  </p>
                </td>
                <td
                  style="width:265.05pt; border-style:solid; border-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:middle"
                >
                  <p
                    style="margin-top:0pt; margin-bottom:0pt; line-height:150%; widows:0; orphans:0; font-size:12pt"
                  >
                    <span style="font-family:'仿宋'">中关村中学</span>
                  </p>
                </td>
              </tr>
              <tr style="height:32.25pt">
                <td
                  style="width:39.8pt; border-style:solid; border-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:middle"
                >
                  <p
                    style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; widows:0; orphans:0; font-size:12pt"
                  >
                    <span style="font-family:'仿宋'">小学      （4所）</span>
                  </p>
                </td>
                <td
                  style="width:265.05pt; border-style:solid; border-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:middle"
                >
                  <p
                    style="margin-top:0pt; margin-bottom:0pt; line-height:150%; widows:0; orphans:0; font-size:12pt"
                  >
                    <span style="font-family:'仿宋'"
                      >中关村小学、西夏十小、西夏十二小、西夏回民小学</span
                    >
                  </p>
                </td>
              </tr>
              <tr style="height:32.25pt">
                <td
                  rowspan="2"
                  style="border-style:solid; border-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:middle"
                >
                  <p
                    style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; widows:0; orphans:0; font-size:12pt"
                  >
                    <span style="font-family:'仿宋'">5</span>
                  </p>
                </td>
                <td
                  rowspan="2"
                  style="width:74.6pt; border-style:solid; border-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:middle"
                >
                  <p
                    style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; widows:0; orphans:0; font-size:12pt"
                  >
                    <span style="font-family:'仿宋'"
                      >贺兰县        （4所）</span
                    >
                  </p>
                </td>
                <td
                  style="width:39.8pt; border-style:solid; border-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:middle"
                >
                  <p
                    style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; widows:0; orphans:0; font-size:12pt"
                  >
                    <span style="font-family:'仿宋'">初中      （1所）</span>
                  </p>
                </td>
                <td
                  style="width:265.05pt; border-style:solid; border-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:middle"
                >
                  <p
                    style="margin-top:0pt; margin-bottom:0pt; line-height:150%; widows:0; orphans:0; font-size:12pt"
                  >
                    <span style="font-family:'仿宋'">贺兰二中</span>
                  </p>
                </td>
              </tr>
              <tr style="height:32.25pt">
                <td
                  style="width:39.8pt; border-style:solid; border-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:middle"
                >
                  <p
                    style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; widows:0; orphans:0; font-size:12pt"
                  >
                    <span style="font-family:'仿宋'">小学       （3所）</span>
                  </p>
                </td>
                <td
                  style="width:265.05pt; border-style:solid; border-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:middle"
                >
                  <p
                    style="margin-top:0pt; margin-bottom:0pt; line-height:150%; widows:0; orphans:0; font-size:12pt"
                  >
                    <span style="font-family:'仿宋'"
                      >贺兰二小、贺兰德胜实验小学、贺兰银光小学（农村）</span
                    >
                  </p>
                </td>
              </tr>
              <tr style="height:32.25pt">
                <td
                  style="border-style:solid; border-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:middle"
                >
                  <p
                    style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; widows:0; orphans:0; font-size:12pt"
                  >
                    <span style="font-family:'仿宋'">6</span>
                  </p>
                </td>
                <td
                  style="width:74.6pt; border-style:solid; border-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:middle"
                >
                  <p
                    style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; widows:0; orphans:0; font-size:12pt"
                  >
                    <span style="font-family:'仿宋'"
                      >永宁县        （3所）</span
                    >
                  </p>
                </td>
                <td
                  style="width:39.8pt; border-style:solid; border-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:middle"
                >
                  <p
                    style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; widows:0; orphans:0; font-size:12pt"
                  >
                    <span style="font-family:'仿宋'">小学       （3所）</span>
                  </p>
                </td>
                <td
                  style="width:265.05pt; border-style:solid; border-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:middle"
                >
                  <p
                    style="margin-top:0pt; margin-bottom:0pt; line-height:150%; widows:0; orphans:0; font-size:12pt"
                  >
                    <span style="font-family:'仿宋'"
                      >永宁县第二小学、永宁县闽宁二小（农村）、永泰小学（市实验共同体）</span
                    >
                  </p>
                </td>
              </tr>
              <tr style="height:32.25pt">
                <td
                  rowspan="2"
                  style="border-style:solid; border-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:middle"
                >
                  <p
                    style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; widows:0; orphans:0; font-size:12pt"
                  >
                    <span style="font-family:'仿宋'">7</span>
                  </p>
                </td>
                <td
                  rowspan="2"
                  style="width:74.6pt; border-style:solid; border-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:middle"
                >
                  <p
                    style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; widows:0; orphans:0; font-size:12pt"
                  >
                    <span style="font-family:'仿宋'"
                      >灵武市        （4所）</span
                    >
                  </p>
                </td>
                <td
                  style="width:39.8pt; border-style:solid; border-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:middle"
                >
                  <p
                    style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; widows:0; orphans:0; font-size:12pt"
                  >
                    <span style="font-family:'仿宋'">初中      （1所）</span>
                  </p>
                </td>
                <td
                  style="width:265.05pt; border-style:solid; border-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:middle"
                >
                  <p
                    style="margin-top:0pt; margin-bottom:0pt; line-height:150%; widows:0; orphans:0; font-size:12pt"
                  >
                    <span style="font-family:'仿宋'">灵武二中</span>
                  </p>
                </td>
              </tr>
              <tr style="height:32.25pt">
                <td
                  style="width:39.8pt; border-style:solid; border-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:middle"
                >
                  <p
                    style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; widows:0; orphans:0; font-size:12pt"
                  >
                    <span style="font-family:'仿宋'">小学       （3所）</span>
                  </p>
                </td>
                <td
                  style="width:265.05pt; border-style:solid; border-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:middle"
                >
                  <p
                    style="margin-top:0pt; margin-bottom:0pt; line-height:150%; widows:0; orphans:0; font-size:12pt"
                  >
                    <span style="font-family:'仿宋'"
                      >灵武二小、东塔回民小学（孵化、农村）、狼皮子梁小学</span
                    >
                  </p>
                </td>
              </tr>
              <tr style="height:32.25pt">
                <td
                  colspan="2"
                  style="width:97.45pt; border-style:solid; border-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:middle"
                >
                  <p
                    style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; widows:0; orphans:0; font-size:12pt"
                  >
                    <span style="font-family:'仿宋'; font-weight:bold"
                      >统计</span
                    >
                  </p>
                </td>
                <td
                  colspan="2"
                  style="width:315.65pt; border-style:solid; border-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:middle"
                >
                  <p
                    style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:150%; widows:0; orphans:0; font-size:12pt"
                  >
                    <span style="font-family:'仿宋'; font-weight:bold"
                      >初中学校12所，小学28所，共计40所学校</span
                    >
                  </p>
                </td>
              </tr>
            </table>
          </div>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"
          >
            <a name="_Toc46321530"
              ><span style="font-family:Calibri">&#xa0;</span></a
            >
          </p>
          <h2
            style="margin-top:13pt; margin-bottom:13pt; text-align:justify; page-break-inside:avoid; page-break-after:avoid; line-height:172%; widows:0; orphans:0; font-size:14pt"
          >
            <a name="_Toc133"
              ><span style="font-family:'仿宋'">（</span
              ><span style="font-family:'仿宋'">四</span
              ><span style="font-family:'仿宋'">）实施方案</span
              ><span style="-aw-bookmark-end:_Toc46321530"></span
            ></a>
          </h2>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'"
              >2020年，项目正式启动，为了建设</span
            ><span style="font-family:'仿宋'"
              >“中小学素养阅读生态区”，共读工程计划实施三年，本实施方案主要聚集在第一年，具体实施如下</span
            ><span style="font-family:'仿宋'">：</span>
          </p>
          <h3
            style="margin-top:13pt; margin-bottom:13pt; text-align:justify; page-break-inside:avoid; page-break-after:avoid; line-height:172%; widows:0; orphans:0; font-size:14pt"
          >
            <a name="_Toc9647"></a
            ><a name="_Toc46321531"
              ><span style="font-family:'仿宋'">1.调研阅读基础</span
              ><span style="-aw-bookmark-end:_Toc9647"></span
            ></a>
          </h3>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'"
              >2020年1月初，项目组对银川40所项目校进行摸底调研，重点了解学校目前阅读开展情况，教师、学生和家长的阅读现状和需求，以便后期有针对性地制定方案，并开展相关活动。</span
            >
          </p>
          <h3
            style="margin-top:13pt; margin-bottom:13pt; text-align:justify; page-break-inside:avoid; page-break-after:avoid; line-height:172%; widows:0; orphans:0; font-size:14pt"
          >
            <a name="_Toc46321532"></a
            ><a name="_Toc867"
              ><span style="font-family:'仿宋'">2.培养阅读习惯</span
              ><span style="-aw-bookmark-end:_Toc46321532"></span
            ></a>
          </h3>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; text-align:justify; line-height:150%; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'">共读工程第一年以</span
            ><span style="font-family:'仿宋'">阅读</span
            ><span style="font-family:'仿宋'">活动</span
            ><span style="font-family:'仿宋'">为重点，</span
            ><span style="font-family:'仿宋'">通过活动激发学生阅读兴趣</span
            ><span style="font-family:'仿宋'">，</span
            ><span style="font-family:'仿宋'">培养阅读习惯</span
            ><span style="font-family:'仿宋'">。</span>
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; text-align:justify; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'; font-weight:bold"
              >阶段一：养成阅读习惯</span
            >
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; text-align:justify; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'; font-weight:bold">（1）</span
            ><span style="font-family:'仿宋'; font-weight:bold"
              >21天阅读活动</span
            >
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; text-align:justify; line-height:150%; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'"
              >在行为心理学中，认为一个人的新习惯或理念的形成并得以巩固至少需要21天的现象，称之为21天效应。组织21天</span
            ><span style="font-family:'仿宋'">阅读</span
            ><span style="font-family:'仿宋'">活动</span
            ><span style="font-family:'仿宋'">，可以让学生</span
            ><span style="font-family:'仿宋'"
              >在成就系统的激励下，激发阅读兴趣，养成阅读习惯，并通过系统平台进行展示分享，保证在阅读后有所记录，有所感悟。</span
            >
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; text-align:justify; line-height:150%; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'"
              >活动要求：注册登录乐智悦读APP，自选</span
            ><span style="font-family:'仿宋'">阅读</span
            ><span style="font-family:'仿宋'">书目</span
            ><span style="font-family:'仿宋'">，</span
            ><span style="font-family:'仿宋'"
              >坚持亲子阅读记录，可以采用文字、图片等多种形式。</span
            >
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; text-align:justify; line-height:150%; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'">活动时间</span
            ><span style="font-family:'仿宋'">：2020年1月14日-</span
            ><span style="font-family:'仿宋'">2月</span
            ><span style="font-family:'仿宋'">28日</span>
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-align:center; widows:0; orphans:0; font-size:12pt"
          >
            <img
              src="./img/aej1n-66wvl.002.png"
              width="188"
              height="376"
              alt=""
              style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline"
            />
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; widows:0; orphans:0; font-size:12pt"
          >
            <span style="font-family:'仿宋'">图：21天阅读活动打卡示例</span>
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; widows:0; orphans:0; font-size:12pt"
          >
            <span style="font-family:'仿宋'">&#xa0;</span>
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; widows:0; orphans:0; font-size:12pt"
          >
            <img
              src="./img/aej1n-66wvl.003.png"
              width="216"
              height="404"
              alt=""
              style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline"
            /><span style="font-family:'仿宋'"> </span
            ><img
              src="./img/aej1n-66wvl.004.png"
              width="210"
              height="404"
              alt=""
              style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline"
            />
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; widows:0; orphans:0; font-size:12pt"
          >
            <span style="font-family:'仿宋'">图：21天阅读活动打卡（部分）</span>
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; widows:0; orphans:0; font-size:12pt"
          >
            <span style="font-family:'仿宋'">&#xa0;</span>
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; text-align:justify; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'; font-weight:bold">（2）</span
            ><span style="font-family:'仿宋'; font-weight:bold">书目</span
            ><span style="font-family:'仿宋'; font-weight:bold">代言活动</span>
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28.5pt; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'"
              >为进一步激发学生的阅读兴趣，通过前期21天的阅读活动，学生逐步养成阅读习惯，随后再通过“书目代言活动”，让学生用简单的方式推荐自己喜欢的图书，分享阅读感受，在线互动交流。如2020年世界读书日发起的“4.23
              我为爱书代言”活动</span
            ><span style="font-family:'仿宋'">。</span>
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; widows:0; orphans:0; font-size:12pt"
          >
            <img
              src="./img/aej1n-66wvl.005.png"
              width="352"
              height="252"
              alt=""
              style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline"
            />
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; widows:0; orphans:0; font-size:12pt"
          >
            <span style="font-family:'仿宋'">图：</span
            ><span style="font-family:'仿宋'">《</span
            ><span style="font-family:'仿宋'">孤独是生命的礼物</span
            ><span style="font-family:'仿宋'">》</span
            ><span style="font-family:'仿宋'">推荐语</span>
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; widows:0; orphans:0; font-size:12pt"
          >
            <img
              src="./img/aej1n-66wvl.006.png"
              width="352"
              height="306"
              alt=""
              style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline"
            />
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; widows:0; orphans:0; font-size:12pt"
          >
            <span style="font-family:'仿宋'">图：</span
            ><span style="font-family:'仿宋'">《</span
            ><span style="font-family:'仿宋'">钢铁是怎样炼成的</span
            ><span style="font-family:'仿宋'">》</span
            ><span style="font-family:'仿宋'">推荐语</span>
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; widows:0; orphans:0; font-size:12pt"
          >
            <span style="font-family:'仿宋'">&#xa0;</span>
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; text-align:justify; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'; font-weight:bold">（3）</span
            ><span style="font-family:'仿宋'; font-weight:bold"
              >悦读乐写活动</span
            >
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; text-align:justify; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'"
              >通过前两个在线阅读活动，学生逐步养成每日阅读的习惯，并能公开快速地分享展示，通过系统平台的成就机制获得及时反馈和现时满足感。此次适时引入深度地输出型悦读乐写活动，结合学生前期的阅读书目和阅读内容，自拟角度，有态度有思考地进行输出表达，并在项目校内进行公开评选。一方面通过活动让学生的阅读感受以文本、图片等形式进行规范地可视化呈现，另一方面通过评选行为选拔优秀，发挥示范引领作用。</span
            >
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; text-align:justify; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'">本活动共由三部分组成</span
            ><span style="font-family:'仿宋'">，分别是：</span>
          </p>
          <p
            style="margin-top:0pt; margin-left:49pt; margin-bottom:0pt; text-indent:-21pt; text-align:justify; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:Wingdings"></span
            ><span style="font:7pt 'Times New Roman'"
              >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
            ><span style="font-family:'仿宋'">征文提交阶段</span
            ><span style="font-family:'仿宋'">（2020年3月2日-</span
            ><span style="font-family:'仿宋'">4月</span
            ><span style="font-family:'仿宋'">5日）</span>
          </p>
          <p
            style="margin-top:0pt; margin-left:49pt; margin-bottom:0pt; text-indent:-21pt; text-align:justify; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:Wingdings"></span
            ><span style="font:7pt 'Times New Roman'"
              >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
            ><span style="font-family:'仿宋'">校内推选阶段（2020年4月24日-</span
            ><span style="font-family:'仿宋'">5月</span
            ><span style="font-family:'仿宋'">20日）</span>
          </p>
          <p
            style="margin-top:0pt; margin-left:49pt; margin-bottom:0pt; text-indent:-21pt; text-align:justify; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:Wingdings"></span
            ><span style="font:7pt 'Times New Roman'"
              >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
            ><span style="font-family:'仿宋'">专家评审阶段（2020年5月25日-</span
            ><span style="font-family:'仿宋'">6月</span
            ><span style="font-family:'仿宋'">5日）</span>
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; text-align:justify; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'"
              >并最终将对优秀教师和学生进行表彰，优秀作品结集成册。</span
            >
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-align:center; widows:0; orphans:0; font-size:0pt"
          >
            <span style="font-family:'Times New Roman'">&#xa0;</span>
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-align:center; widows:0; orphans:0; font-size:14pt"
          >
            <img
              src="./img/aej1n-66wvl.007.png"
              width="346"
              height="328"
              alt=""
              style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline"
            />
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-align:center; widows:0; orphans:0; font-size:12pt"
          >
            <span style="font-family:'仿宋'"
              >图：悦读乐写征文活动作品（部分）</span
            >
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'; font-weight:bold">
              阶段二：激发阅读兴趣</span
            >
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'"> </span
            ><span style="font-family:'仿宋'"> </span
            ><span style="font-family:'仿宋'"
              >经过前一个阶段的习惯养成，进入第二阶段通过丰富的听说读写各类活动形式激发学生的阅读兴趣。</span
            >
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; text-align:justify; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'; font-weight:bold">（1）</span
            ><span style="font-family:'仿宋'; font-weight:bold"
              >端午节主题朗诵活动</span
            >
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:30pt; text-align:justify; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'"
              >项目组在第一年重点以上半年的端午节和下半年的国庆节为重大节日，组织开展面向项目校的主题式趣味活动。其中</span
            ><span style="font-family:'仿宋'">，</span
            ><span style="font-family:'仿宋'"
              >端午节活动以“读屈原名诗，传爱国情怀”为主题，请学生选择自己喜爱的屈原诗歌，进行有感情地配乐朗诵，综合学生点赞情况和作品质量，对优秀作品进行表彰。</span
            >
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-align:center; widows:0; orphans:0; font-size:14pt"
          >
            <img
              src="./img/aej1n-66wvl.008.png"
              width="276"
              height="210"
              alt=""
              style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline"
            /><img
              src="./img/aej1n-66wvl.009.png"
              width="276"
              height="210"
              alt=""
              style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline"
            />
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-align:center; widows:0; orphans:0; font-size:10.5pt"
          >
            <img
              src="./img/aej1n-66wvl.010.png"
              width="278"
              height="208"
              alt=""
              style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline"
            /><img
              src="./img/aej1n-66wvl.011.png"
              width="274"
              height="208"
              alt=""
              style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline"
            />
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-align:center; widows:0; orphans:0; font-size:12pt"
          >
            <span style="font-family:'仿宋'">图：端午节朗诵作品（部分）</span>
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-align:center; widows:0; orphans:0; font-size:12pt"
          >
            <span style="font-family:'仿宋'">&#xa0;</span>
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; text-align:justify; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'; font-weight:bold">（2）</span
            ><span style="font-family:'仿宋'; font-weight:bold"
              >国庆节主题书法活动</span
            >
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; text-align:justify; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'"
              >下半年以“不忘初心
              书写中国”为主题开展国庆活动，项目组提供书写样纸，让学生按要求用书法向祖国献礼。活动既能弘扬学生的爱国情怀，也能侧面了解到银川项目校学生的书写水平，从而在后面的课程活动中有针对性地提供指导。</span
            >
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-align:center; widows:0; orphans:0; font-size:10.5pt"
          >
            <a name="_Toc46321533"
              ><img
                src="./img/aej1n-66wvl.012.png"
                width="344"
                height="356"
                alt=""
                style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline"/><img
                src="./img/aej1n-66wvl.013.png"
                width="342"
                height="394"
                alt=""
                style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline"
            /></a>
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-align:center; widows:0; orphans:0; font-size:12pt"
          >
            <span style="font-family:'仿宋'">图：国庆节书法作品（部分）</span>
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-align:center; widows:0; orphans:0; font-size:10.5pt"
          >
            <span style="font-family:'仿宋'">&#xa0;</span>
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; text-align:justify; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'; font-weight:bold"
              >阶段三：指导阅读方法</span
            >
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; text-align:justify; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'"
              >在经历了第一阶段习惯养成、第二阶段兴趣激发后，本阶段主要通过活动的方式给项目校师生提供一些阅读方面的课程指导。</span
            ><span style="font-family:'仿宋'"
              >活动采取指定书单+导读单的方式进行，项目组为中小学生提供指定书单的导读手册，</span
            ><span style="font-family:'仿宋'"
              >手册中用趣味性地问题导引和检测学生的阅读效果，既能有效激发学生的阅读兴趣，同时也为后面阅读方法的指导提供抓手</span
            ><span style="font-family:'仿宋'">。</span>
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; text-align:justify; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'; font-weight:bold">（</span
            ><span style="font-family:'仿宋'; font-weight:bold">1</span
            ><span style="font-family:'仿宋'; font-weight:bold">）</span
            ><span style="font-family:'仿宋'; font-weight:bold"
              >暑期“同读一本书”活动</span
            >
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; text-align:justify; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'"
              >为了充分利用暑期时间，让项目校师生创设阅读氛围，扩大阅读量，项目组在暑期将举办“同读一本书”系列活动，</span
            ><span style="font-family:'仿宋'"
              >依托九月份开学后统编教材指定整本书阅读的书单，借助项目组提供的导读单，帮助学生做好新学期的预习，又能通过趣味化的问题检测学生阅读能力，</span
            ><span style="font-family:'仿宋'"
              >从而更精准地了解学生的阅读情况，开展阅读指导。</span
            >
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; widows:0; orphans:0; font-size:10.5pt"
          >
            <img
              src="./img/aej1n-66wvl.014.png"
              width="472"
              height="338"
              alt=""
              style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline"
            />
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; widows:0; orphans:0; font-size:12pt"
          >
            <span style="font-family:'仿宋'">图：</span
            ><span style="font-family:'宋体'; font-size:10.5pt">《</span
            ><span style="font-family:'宋体'; font-size:10.5pt">安徒生童话</span
            ><span style="font-family:'宋体'; font-size:10.5pt">》</span
            ><span style="font-family:'宋体'; font-size:10.5pt"
              >导读手册学生作品</span
            >
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; widows:0; orphans:0; font-size:10.5pt"
          >
            <img
              src="./img/aej1n-66wvl.015.png"
              width="310"
              height="434"
              alt=""
              style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline"
            />
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; widows:0; orphans:0; font-size:12pt"
          >
            <span style="font-family:'仿宋'">图：</span
            ><span style="font-family:'宋体'; font-size:10.5pt">《</span
            ><span style="font-family:'宋体'; font-size:10.5pt"
              >孤独的小螃蟹</span
            ><span style="font-family:'宋体'; font-size:10.5pt">》</span
            ><span style="font-family:'宋体'; font-size:10.5pt"
              >导读手册学生作品</span
            >
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; widows:0; orphans:0; font-size:10.5pt"
          >
            <img
              src="./img/aej1n-66wvl.016.png"
              width="310"
              height="394"
              alt=""
              style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline"
            />
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; widows:0; orphans:0; font-size:12pt"
          >
            <span style="font-family:'仿宋'">图：</span
            ><span style="font-family:'宋体'; font-size:10.5pt"
              >《没头脑和不高兴》导读手册学生作品</span
            >
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; widows:0; orphans:0; font-size:10.5pt"
          >
            <span style="font-family:Calibri">&#xa0;</span>
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; widows:0; orphans:0; font-size:10.5pt"
          >
            <img
              src="./img/aej1n-66wvl.017.png"
              width="502"
              height="320"
              alt="灵武二中八年级17董艺蕊6"
              style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline"
            />
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; widows:0; orphans:0; font-size:12pt"
          >
            <span style="font-family:'仿宋'">图：</span
            ><span style="font-family:'宋体'; font-size:10.5pt">《</span
            ><span style="font-family:'宋体'; font-size:10.5pt"
              >红星照耀中国</span
            ><span style="font-family:'宋体'; font-size:10.5pt">》</span
            ><span style="font-family:'宋体'; font-size:10.5pt"
              >导读手册学生作品</span
            >
          </p>
          <h3
            style="margin-top:13pt; margin-bottom:13pt; text-align:justify; page-break-inside:avoid; page-break-after:avoid; line-height:172%; widows:0; orphans:0; font-size:14pt"
          >
            <a name="_Toc16476"
              ><span style="font-family:'仿宋'">3.匹配阅读资源</span
              ><span style="-aw-bookmark-end:_Toc46321533"></span
            ></a>
          </h3>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:21pt; text-align:justify; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'"
              >共读工程针对中小学师生在听说读写方面的学习需求，以新部编版教材为基础，提供多样化优质资源：</span
            >
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; text-align:justify; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'; font-weight:bold">（</span
            ><span style="font-family:'仿宋'; font-weight:bold">1</span
            ><span style="font-family:'仿宋'; font-weight:bold">）</span
            ><span style="font-family:'仿宋'; font-weight:bold"
              >整本书阅读学习资源</span
            >
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; text-align:justify; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'"
              >综合教育部推荐必读书目、清华附小推荐书目、新课标推荐书目及中国小学生基础阅读书目等多维方向，进行全本阅读书目选择，目前已有近</span
            ><span style="font-family:'仿宋'">6</span
            ><span style="font-family:'仿宋'"
              >00本全本阅读书目，及其配套精选试题，学生可在线下阅读后，在线上进行测评，以了解自己的阅读效果</span
            ><span style="font-family:'仿宋'">。</span>
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; text-align:justify; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'; font-weight:bold">（</span
            ><span style="font-family:'仿宋'; font-weight:bold">2</span
            ><span style="font-family:'仿宋'; font-weight:bold">）</span
            ><span style="font-family:'仿宋'; font-weight:bold"
              >单元主题阅读学习资源</span
            >
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; text-align:justify; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'"
              >同步统编语文教材目录，以教材单元为结构，匹配单元主题相关的中短篇阅读篇目，并提供在线阅读资源和配套试题，学生</span
            ><span style="font-family:'仿宋'">可</span
            ><span style="font-family:'仿宋'"
              >一边读一边练，实时获取阅读报告，了解阅读能力</span
            ><span style="font-family:'仿宋'">。</span>
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; text-align:justify; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'; font-weight:bold">（</span
            ><span style="font-family:'仿宋'; font-weight:bold">3</span
            ><span style="font-family:'仿宋'; font-weight:bold">）</span
            ><span style="font-family:'仿宋'; font-weight:bold"
              >朗读、诵读及生字词学习等音频资源</span
            >
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; text-align:justify; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'"
              >邀请国家一级播音员录制专业、规范的统编版语文教材中全部课文及所有生字、生词</span
            ><span style="font-family:'仿宋'">。</span>
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; text-align:justify; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'; font-weight:bold">（</span
            ><span style="font-family:'仿宋'; font-weight:bold">4</span
            ><span style="font-family:'仿宋'; font-weight:bold">）</span
            ><span style="font-family:'仿宋'; font-weight:bold"
              >欧阳中石先生团队的书写教学视频资源</span
            >
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; text-align:justify; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'"
              >匹配统编教材要求，生字词的字音、字义、字形演变、字形解析及书写视频等优质课程资源，让学生在认识、理解生字、生词的基础上写好规范字。</span
            >
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; text-align:justify; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'"
              >以上资源集合在乐智悦读APP中，为了让项目校师生更好地应用，同时鉴于项目校范围广、疫情阶段时期特殊，项目组各组织一场面向教师和学生的“乐智悦读</span
            ><span style="font-family:'仿宋'">APP任务指南”活动</span
            ><span style="font-family:'仿宋'">。</span
            ><span style="font-family:'仿宋'">活动</span
            ><span style="font-family:'仿宋'">在线</span
            ><span style="font-family:'仿宋'"
              >提供各模块操作视频，教师和学生可边学习边操作</span
            ><span style="font-family:'仿宋'">，</span
            ><span style="font-family:'仿宋'">配上常见问题指南手册</span
            ><span style="font-family:'仿宋'">，</span
            ><span style="font-family:'仿宋'">便捷师生使用</span
            ><span style="font-family:'仿宋'">，</span
            ><span style="font-family:'仿宋'"
              >最后通过提交学习任务的完成截图</span
            ><span style="font-family:'仿宋'">，保障学习</span
            ><span style="font-family:'仿宋'">效果</span
            ><span style="font-family:'仿宋'">。</span>
          </p>
          <h3
            style="margin-top:13pt; margin-bottom:13pt; text-align:justify; page-break-inside:avoid; page-break-after:avoid; line-height:172%; widows:0; orphans:0; font-size:14pt"
          >
            <a name="_Toc3619"></a
            ><a name="_Toc46321534"
              ><span style="font-family:'仿宋'">4.营造阅读环境</span
              ><span style="-aw-bookmark-end:_Toc3619"></span
            ></a>
          </h3>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; line-height:150%; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'"
              >本次项目组将根据前期项目校参与数据，从</span
            ><span style="font-family:'仿宋'">银川</span
            ><span style="font-family:'仿宋'"
              >40所项目校中选择3所小学3所中学，分别</span
            ><span style="font-family:'仿宋'">配置一台</span
            ><span style="font-family:'仿宋'">智能共读</span
            ><span style="font-family:'仿宋'">分享系统</span
            ><span style="font-family:'仿宋'">。</span>
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; line-height:150%; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'">智能共读</span
            ><span style="font-family:'仿宋'">分享系统</span
            ><span style="font-family:'仿宋'"
              >由云端实现控制、管理，方便学生借书，培养学生阅读习惯。通过图书</span
            ><span style="font-family:'仿宋'">共享的理念</span
            ><span style="font-family:'仿宋'"
              >，在提高学生阅读兴趣、扩大阅读量的同时，大大减轻家长购书负担。</span
            >
          </p>
          <h3
            style="margin-top:13pt; margin-bottom:13pt; text-align:justify; page-break-inside:avoid; page-break-after:avoid; line-height:172%; widows:0; orphans:0; font-size:14pt"
          >
            <a name="_Toc16874"></a
            ><a name="_Toc46321535"
              ><span style="font-family:'仿宋'">5.指导阅读方法</span
              ><span style="-aw-bookmark-end:_Toc16874"></span
            ></a>
          </h3>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:30pt; text-align:justify; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'"
              >共读工程2020年的阅读方法指导，主要从教师层面入手，配合家校活动开展实施。共读工程通过前期“21天阅读活动”的参与情况，在银川项目校中选拔出300位种子教师，本年度面向种子教师开展培训。培训分为面授培训和网络培训，面授培训主要定位在理念提升和同课异构的专项指导；网络培训主要进行阅读方法指导和案例实操。</span
            >
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; text-align:justify; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'; font-weight:bold">（</span
            ><span style="font-family:'仿宋'; font-weight:bold">1</span
            ><span style="font-family:'仿宋'; font-weight:bold">）</span
            ><span style="font-family:'仿宋'; font-weight:bold">面授培训</span>
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:30pt; text-align:justify; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'">2020年共进行</span
            ><span style="font-family:'仿宋'">2</span
            ><span style="font-family:'仿宋'"
              >次面授培训，以下列出了培训的主要类型和相应内容，具体主题和时间待疫情结束学校复课情况，双方协商确定。</span
            >
          </p>
          <table
            cellspacing="0"
            cellpadding="0"
            style="width:431.4pt; border-collapse:collapse"
          >
            <tr style="height:17.25pt">
              <td
                style="width:43.65pt; border-style:solid; border-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top; background-color:#d8d8d8"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'; font-weight:bold">序号</span>
                </p>
              </td>
              <td
                style="width:89pt; border-style:solid; border-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top; background-color:#d8d8d8"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'; font-weight:bold"
                    >培训类型</span
                  >
                </p>
              </td>
              <td
                style="width:265.6pt; border-style:solid; border-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top; background-color:#d8d8d8"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'; font-weight:bold"
                    >培训内容</span
                  >
                </p>
              </td>
            </tr>
            <tr style="height:30pt">
              <td
                style="width:43.65pt; border-style:solid; border-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">1</span>
                </p>
              </td>
              <td
                style="width:89pt; border-style:solid; border-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">专家示范课</span>
                </p>
              </td>
              <td
                style="width:265.6pt; border-style:solid; border-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'"
                    >以案例教学为主，通过不同文体、不同学科的阅读课例观摩进行针对性的课例指导。</span
                  >
                </p>
              </td>
            </tr>
            <tr style="height:37.5pt">
              <td
                style="width:43.65pt; border-style:solid; border-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">2</span>
                </p>
              </td>
              <td
                style="width:89pt; border-style:solid; border-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">银川教师诊断课</span>
                </p>
              </td>
              <td
                style="width:265.6pt; border-style:solid; border-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'"
                    >本模块采用参训教师上诊断课，专家听评课并提供针对性的指导提升。</span
                  >
                </p>
              </td>
            </tr>
          </table>

          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; text-align:justify; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'; font-weight:bold">（</span
            ><span style="font-family:'仿宋'; font-weight:bold">2</span
            ><span style="font-family:'仿宋'; font-weight:bold">）</span
            ><span style="font-family:'仿宋'; font-weight:bold">网络培训</span>
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; text-align:justify; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'">2020</span
            ><span style="font-family:'仿宋'"
              >年共读种子教师的网络培训将采用“</span
            ><span style="font-family:'仿宋'"
              >1+1+3”的模式开展：第一个“1”计划以校长研讨会的形式作为项目培训的启动仪式，从学校阅读开展入手，一方面将未来种子教师的培训工作融入到学校整体工作之中，增强学校对种子教师的重视程度；另一方面让京银两地的校长有一个分享交流的机会，共享阅读方面的经验和教训。第二个“1”拟计划邀请阅读方面的专家从教师层面来谈阅读的重要性和必要性，让老师理解阅读不仅是教学需要，更对自身和学生受益一生。第三个“3”将采用主题培训的方式，平均8-10周为一个主题，下半年计划围绕中小学阅读的三个主题开展。</span
            >
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'; font-weight:bold"
              >（1）校长研讨会</span
            >
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'"
              >2020年新型冠状病毒肺炎突如其来，学校原教学计划被打破，在疫情当下，学校如何应对疫情，如何在网络教学的情况下开展阅读教学等。基于以上的诸多问题，共读项目组拟计划于</span
            ><span style="font-family:'仿宋'">2020年6月</span
            ><span style="font-family:'仿宋'"
              >邀请北京和银川的校长代表，以在线直播互动的形式开展校长研讨会，作为本次共读工程种子教师培训的启动仪式。一方面分享北京和银川两地的代表学校在阅读方面的工作和成绩，一方面立足校本特色，交流学校在阅读开展过程中的困惑和问题。</span
            >
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'"
              >本次研讨会的主题是“定位学校特色，聚焦阅读新生态”，两地校长将重点在如何打造阅读示范校；如何以阅读促进课程教学观念革新，重塑学校文化；怎样推动阅读在学校可持续发展、常态化进行等问题上展开交流分享。</span
            >
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'"
              >将校长研讨会作为此次种子教师培训的启动仪式，意义有三：一增强校长及学校在此项工作中的参与感和支持度，让种子教师在后期培训中无后顾之忧；二真实反映学校存在的阅读现状和问题，便于共读项目有针对性地提供指导和帮助，有的放矢地开展活动和培训；三凸显学校作为阅读效果的载体，融合教师、学生和学校的一体化关系，让种子教师经过系统培训能更好地助力学校阅读工作的开展，让更多的老师和学生能通过共读工程有所受益。</span
            >
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'; font-weight:bold"
              >（2）专家讲座</span
            >
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; text-align:justify; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'"
              >邀请国内阅读方面的知名专家，从阅读理念层面既高屋建瓴地提高教师思想意识，同时能从实际的阅读课程、阅读活动方面分享经验和方法，让银川教师在讲座中能感同身受、有所共鸣。</span
            >
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'; font-weight:bold"
              >（3）主题培训</span
            >
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; text-align:justify; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'"
              >本次共读种子教师培训将以主题开展培训设计</span
            ><span style="font-family:'仿宋'">，</span
            ><span style="font-family:'仿宋'"
              >每个主题将采用理论+实践+分享提升的思路开展，项目组会为参训教师提供主题培训的相关资料，结合课内教学内容，不增加教师负担，同时提供阅读手册，供教师在设计课程时、家校活动参与时有所参考。</span
            >
          </p>
          <table
            cellspacing="0"
            cellpadding="0"
            style="width:101%; border-collapse:collapse"
          >
            <tr style="height:25pt">
              <td
                style="width:14.64%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle; background-color:#d8d8d8"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'; font-weight:bold">日期</span>
                </p>
              </td>
              <td
                style="width:33.68%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle; background-color:#d8d8d8"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'; font-weight:bold">主题</span>
                </p>
              </td>
              <td
                style="width:51.66%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle; background-color:#d8d8d8"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'; font-weight:bold">内容</span>
                </p>
              </td>
            </tr>
            <tr style="height:25pt">
              <td
                style="width:14.64%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">6月28日</span>
                </p>
              </td>
              <td
                style="width:33.68%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'"
                    >基于统编教材整本书阅读的策略与方法</span
                  >
                </p>
              </td>
              <td
                style="width:51.66%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'"
                    >本课程聚焦统编教材，从整本书阅读的目标、实施的基本流程、不同阶段指导的方法与策略等方面进行介绍。通过具体的案例呈现整本书阅读实施过程中教师指导的方式与方法，助力语文教师的专业成长。</span
                  >
                </p>
              </td>
            </tr>
            <tr style="height:25pt">
              <td
                style="width:14.64%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">7月22日</span>
                </p>
              </td>
              <td
                style="width:33.68%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'"
                    >和孩子一起读——例说整本书阅读的的设计与实施</span
                  >
                </p>
              </td>
              <td
                style="width:51.66%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'"
                    >本课程聚焦统编教材快乐读书吧提供的阅读书目，以课为例介绍教师引领学生进行整本书阅读的基本流程、阅读指导的教学设计以及导读微课的设计</span
                  >
                </p>
              </td>
            </tr>
            <tr style="height:25pt">
              <td
                style="width:14.64%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">8月4日</span>
                </p>
              </td>
              <td
                style="width:33.68%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'"
                    >发现小学生整本书阅读秘密—兼谈统编教材“快乐读书吧”教学策略</span
                  >
                </p>
              </td>
              <td
                style="width:51.66%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'"
                    >发现小学生整本书阅读的秘密——兼谈统编教材“快乐读书吧”的教学策略</span
                  >
                </p>
              </td>
            </tr>
            <tr style="height:25pt">
              <td
                style="width:14.64%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">8月20日</span>
                </p>
              </td>
              <td
                style="width:33.68%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'"
                    >“整本书阅读之教材阅读”教师分享会</span
                  >
                </p>
              </td>
              <td
                style="width:51.66%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'"
                    >随着整本书阅读作为语文课程标准的“任务群”，它的价值和意义得到了无限放大。但是如何真正落实整本书阅读的教学任务，如何激发学生的阅读兴趣，指导阅读方法。今天，让我们一起跟着银川种子教师代表和专家聊一聊！</span
                  >
                </p>
              </td>
            </tr>
            <tr style="height:25pt">
              <td
                style="width:14.64%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">8月26日</span>
                </p>
              </td>
              <td
                style="width:33.68%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">整本书阅读的实践与研究</span>
                </p>
              </td>
              <td
                style="width:51.66%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'"
                    >本课程聚焦如何线上指导学生进行整本书的阅读以及整本书的问题设计与课程分享。通过具体的案例呈现整本书阅读实施过程中教师指导的方式与方法，助力语文教师的专业成长。</span
                  >
                </p>
              </td>
            </tr>
            <tr style="height:25pt">
              <td
                style="width:14.64%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">9月14日</span>
                </p>
              </td>
              <td
                style="width:33.68%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'"
                    >例谈阅读教学中“学生课堂实践活动”的设计</span
                  >
                </p>
              </td>
              <td
                style="width:51.66%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'"
                    >着眼于统编初中语文教材的使用，通过语言的学用、灵动的思考、厚实的积累等课堂实践活动的设计提高阅读教学效率。</span
                  ><span style="font-family:'仿宋'"> </span>
                </p>
              </td>
            </tr>
            <tr style="height:25pt">
              <td
                style="width:14.64%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">10月28日</span>
                </p>
              </td>
              <td
                style="width:33.68%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'"
                    >史家教育集团育人体系的研究教育教学</span
                  >
                </p>
              </td>
              <td
                style="width:51.66%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'"
                    >本次教学研讨会，旨在引导教师依据课程标准和学生实际需求，设计相应的教学活动，努力让每节课不仅成为传播知识的阵地，而且是坚定理想信念，塑造优秀品格的重要载体，让社会主义核心价值观的种子在学生心中生根发芽，真正做到为党育人、为国育才，努力把学生培养成为德智体美劳全面发展的建设者和接班人。
                  </span>
                </p>
              </td>
            </tr>
            <tr style="height:25pt">
              <td
                style="width:14.64%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">11月29日</span>
                </p>
              </td>
              <td
                style="width:33.68%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'"
                    >语文教师偏态教学行为研究</span
                  >
                </p>
              </td>
              <td
                style="width:51.66%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'"
                    >剖析、矫正教学目标定位不准、合作学习虚假、教学媒体滥用等典型的教学偏态行为，以期建立良好的教学生态。</span
                  >
                </p>
              </td>
            </tr>
            <tr style="height:25pt">
              <td
                style="width:14.64%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">12月11日</span>
                </p>
              </td>
              <td
                style="width:33.68%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'"
                    >“京银全科阅读项目”研讨会</span
                  >
                </p>
              </td>
              <td
                style="width:51.66%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'"
                    >阅读是对知识的渴望，会阅读的孩子不会差。为了更好地将阅读融入到全学科课堂教学，促进教师教学方式的改革，提高阅读教育的针对性和实效性，首都师范大学双优云桥项目组联合北京东城区史家小学集团于12月11日上午组织开展《让阅读成为人生的基石》直播活动。</span
                  >
                </p>
              </td>
            </tr>
            <tr style="height:25pt">
              <td
                style="width:14.64%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">12月28日</span>
                </p>
              </td>
              <td
                style="width:33.68%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'"
                    >一件神奇的读书教室—我开设经典夜读课程的经历、做法和反思</span
                  >
                </p>
              </td>
              <td
                style="width:51.66%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'"
                    >分享组建跨班级的“经典夜读小组”七年来的经历、做法和个人的反思。</span
                  >
                </p>
              </td>
            </tr>
          </table>

          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; widows:0; orphans:0; font-size:14pt"
          >
            <a name="_Toc46321536"
              ><span style="font-family:'仿宋'; font-weight:bold">（</span
              ><span style="font-family:'仿宋'; font-weight:bold">4</span
              ><span style="font-family:'仿宋'; font-weight:bold">）</span
              ><span style="font-family:'仿宋'; font-weight:bold"
                >特色培养班级</span
              ></a
            >
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'"
              >为进一步推动共读种子教师的个性化发展，项目组专家计划对有提升意愿的教师进行特色培养，在常态化培训基础上增设培训内容，建立家校联动的阅读推广机制，打造精品阅读展示课例，探索具有银川特色的阅读教学模式，提升中小学师生的阅读素养。</span
            >
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'; font-weight:bold">·家校班</span
            ><span style="font-family:'仿宋'"
              >：该班级限 30
              位种子教师。致力于打造有温度、有深度的家校共育阅读环境，为种子教师提供优质的共读资源，指导种子教师开展优质、高效的共读交流活动，增强家庭和学校的合作度和互动性。</span
            >
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'; font-weight:bold">·优质课班</span
            ><span style="font-family:'仿宋'"
              >：该班级限 24 位种子教师。按 1-2 年级组、3- 4 年级组、5-6
              年级组和初中组四个组别进行分班，每个组别仅限 6
              个名额。主要指导教师如何打造一堂优质阅读课，如何更好展示课堂效果。</span
            >
          </p>
          <h3
            style="margin-top:13pt; margin-bottom:13pt; text-align:justify; page-break-inside:avoid; page-break-after:avoid; line-height:172%; widows:0; orphans:0; font-size:14pt"
          >
            <a name="_Toc3504"
              ><span style="font-family:'仿宋'">6.展示阅读成果</span
              ><span style="-aw-bookmark-end:_Toc46321536"></span
            ></a>
          </h3>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'; font-weight:bold">（</span
            ><span style="font-family:'仿宋'; font-weight:bold">1</span
            ><span style="font-family:'仿宋'; font-weight:bold">）</span
            ><span style="font-family:'仿宋'; font-weight:bold">数据成果</span>
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'"
              >每学期末，分别以班级、年级、学校为单位，通过阅读过程中所产生的阅读时间、阅读测评结果等数据的收集，有效地分析学生阅读效果及阅读能力的发展情况，形成绩效评估报告。</span
            >
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'; font-weight:bold">（</span
            ><span style="font-family:'仿宋'; font-weight:bold">2</span
            ><span style="font-family:'仿宋'; font-weight:bold"
              >）案例成果</span
            >
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'; font-weight:bold">·</span
            ><span style="font-family:'仿宋'; font-weight:bold">学生作品</span
            ><span style="font-family:'仿宋'; font-weight:bold">：</span
            ><span style="font-family:'仿宋'">共读项目组将全年银川项目校</span
            ><span style="font-family:'仿宋'"
              >参与活动的学生成果和优秀作品，如2</span
            ><span style="font-family:'仿宋'">1</span
            ><span style="font-family:'仿宋'"
              >天阅读活动中的阅读感悟；征文活动中学生的阅读思考等，结集成册。</span
            >
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'; font-weight:bold">·</span
            ><span style="font-family:'仿宋'; font-weight:bold">教师课例</span
            ><span style="font-family:'仿宋'; font-weight:bold">：</span
            ><span style="font-family:'仿宋'"
              >本年度教师在参加培训过程中的教学思考和课堂案例，分主题、分模块总结整理，并最终出版教师成果集。</span
            >
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'">（</span
            ><span style="font-family:'仿宋'">3</span
            ><span style="font-family:'仿宋'">）</span
            ><span style="font-family:'仿宋'">共读白皮书</span>
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'"
              >2020年共读工程将根据本年度项目开展情况和成果，发布一本京银共读白皮书。</span
            >
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'; font-weight:bold">（</span
            ><span style="font-family:'仿宋'; font-weight:bold">4</span
            ><span style="font-family:'仿宋'; font-weight:bold">）</span
            ><span style="font-family:'仿宋'; font-weight:bold"
              >双优云桥总结汇报会</span
            >
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; text-align:justify; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'">项目组将协助银川</span
            ><span style="font-family:'仿宋'">举办“双优云桥项目总结汇报会”</span
            ><span style="font-family:'仿宋'"
              >，邀请阅读方面的专家学者共襄盛宴。</span
            ><span style="font-family:'仿宋'"
              >本次会议将通过项目成果汇报、项目校学校、教师、家长进行阅读经验分享与交流，分享并讲述项目背后的故事，促进银川基础教育质量优质均衡发展。</span
            >
          </p>
          <h2
            style="margin-top:13pt; margin-bottom:13pt; text-align:justify; page-break-inside:avoid; page-break-after:avoid; line-height:172%; widows:0; orphans:0; font-size:14pt"
          >
            <a name="_Toc46321525"></a
            ><a name="_Toc31168"
              ><span style="font-family:'仿宋'">（</span
              ><span style="font-family:'仿宋'">五</span
              ><span style="font-family:'仿宋'">）项目特点</span
              ><span style="-aw-bookmark-end:_Toc46321525"></span
            ></a>
          </h2>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'"
              >京银互动一带一路共读工程，秉持高度、广度、深度、温度四大核心理念，研究探索以学生为主体，基于全学科阅读的教学创新改革机制，形成京银共研共创的“中小学素养阅读生态区”特色模式。</span
            >
          </p>
          <h3
            style="margin-top:13pt; margin-bottom:13pt; text-align:justify; page-break-inside:avoid; page-break-after:avoid; line-height:172%; widows:0; orphans:0; font-size:14pt"
          >
            <a name="_Toc31062"></a
            ><a name="_Toc46321526"
              ><span style="font-family:'仿宋'">1.专家引领，提高度</span
              ><span style="-aw-bookmark-end:_Toc31062"></span
            ></a>
          </h3>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'"
              >共读工程依托北京市丰富的优质教育资源，为银川引入不同层面的阅读专家，刷新阅读观念，建构阅读认知，指导阅读方法。朱永新、顾明远等教育大家，解读新高考变革下的阅读理念和阅读形势；温儒敏、曹文轩等新教材编者，直面新教材的发展内涵和核心思想；张立军、王文丽等一线特高级教师，手把手指导阅读授课方法，示范典型课堂。共读工程从观念到认知到方法，聚焦教师基本素养，直击教师课堂教学能力。</span
            ><span style="font-family:'仿宋'"> </span>
          </p>
          <h3
            style="margin-top:13pt; margin-bottom:13pt; text-align:justify; page-break-inside:avoid; page-break-after:avoid; line-height:172%; widows:0; orphans:0; font-size:14pt"
          >
            <a name="_Toc46321527"></a
            ><a name="_Toc22902"
              ><span style="font-family:'仿宋'">2.听说读写，拓广度</span
              ><span style="-aw-bookmark-end:_Toc46321527"></span
            ></a>
          </h3>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'"
              >共读工程以听说读写为基础，涵盖语文、数学、美术、科学等多学科内容，资源形式包括阅读、朗读、识写等，所有资源向银川中小学项目校全面开放。同时，正值</span
            ><span style="font-family:'仿宋'"
              >新型冠状病毒肺炎疫情蔓延之际，银川已确定中小学校延期开学，共读工程提供的资源与新部编版教材内容同步，可助力中小学师生在家随时随地地学习，配合银川空中课堂，切实贯彻“停课不停学”的理念，</span
            ><span style="font-family:'仿宋'"
              >辅导学生复习旧知，预习新知，指导教师掌握并整合课程资源，优化教学方法，提升教与学的质量。</span
            >
          </p>
          <h3
            style="margin-top:13pt; margin-bottom:13pt; text-align:justify; page-break-inside:avoid; page-break-after:avoid; line-height:172%; widows:0; orphans:0; font-size:14pt"
          >
            <a name="_Toc46321528"></a
            ><a name="_Toc24032"
              ><span style="font-family:'仿宋'">3.主题活动，挖深度</span
              ><span style="-aw-bookmark-end:_Toc46321528"></span
            ></a>
          </h3>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; text-align:justify; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'"
              >共读工程面向银川引入系列主题活动，三年内有步骤、有层次地，分别从整本书阅读、写作、单元整合教学、全学科阅读等主题内容，逐步培养学生的阅读能力，提升教师的指导能力。共读工程分主题小切口，</span
            ><span style="font-family:'仿宋'"
              >打通课内与课外，融合阅读环境、阅读课程、阅读培训和阅读活动等，内容不断深化，范围也随着主题从课堂、家庭，不断扩大到学校和社区等。</span
            ><span style="font-family:'仿宋'"
              >项目实施过程中，为银川挖掘种子教师，培育本地团队，深化教育教学效果，创造更广阔的展示平台。</span
            >
          </p>
          <h3
            style="margin-top:13pt; margin-bottom:13pt; text-align:justify; page-break-inside:avoid; page-break-after:avoid; line-height:172%; widows:0; orphans:0; font-size:14pt"
          >
            <a name="_Toc46321529"></a
            ><a name="_Toc24049"
              ><span style="font-family:'仿宋'">4.阅读生态，有温度</span
              ><span style="-aw-bookmark-end:_Toc46321529"></span
            ></a>
          </h3>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'"
              >家校共建一直是基础教育非常关注也非常棘手的地方，家庭对于孩子的成长意义重大，但受限于校内时间有限、家长缺乏指导、家校成果缺乏展示平台等原因，家校共建流于表面。本次共读工程</span
            ><span style="font-family:'仿宋'">以学生为主体，</span
            ><span style="font-family:'仿宋'">创造性地</span
            ><span style="font-family:'仿宋'"
              >引入家庭教育，探索并建设具有银川本地化特色的“中小学素养阅读生态区”。家庭、学校、社区、市县，通过共读工程的建设实施，内部力量不断加强，外部共建京银两地手拉手。利用互联网手段，基于活动和主题让两地学生手拉手、心连心，并邀请两地优秀学生、教师和家庭研学互访，共享知识、交流文化、建立友谊，真正做到即使远隔千山万水，也能实现民族团结一家亲。同时以保障项目结束后，拉手校、教师和学生也能持续交流提升。</span
            >
          </p>
          <h2
            style="margin-top:13pt; margin-bottom:13pt; text-align:justify; page-break-inside:avoid; page-break-after:avoid; line-height:172%; widows:0; orphans:0; font-size:14pt"
          >
            <a name="_Toc705"
              ><span style="font-family:'仿宋'">二、</span
              ><span style="font-family:'仿宋'">项目经费使用情况</span></a
            >
          </h2>
          <h2
            style="margin-top:13pt; margin-bottom:13pt; text-align:justify; page-break-inside:avoid; page-break-after:avoid; line-height:172%; widows:0; orphans:0; font-size:14pt"
          >
            <a name="_Toc32372"
              ><span style="font-family:'仿宋'">（</span
              ><span style="font-family:'仿宋'">一</span
              ><span style="font-family:'仿宋'">）财政资金到位率</span></a
            >
          </h2>
          <div style="text-align:center">
            <table
              cellspacing="0"
              cellpadding="0"
              style="width:94.64%; margin-right:auto; margin-left:auto; border-collapse:collapse"
            >
              <tr style="height:25.5pt">
                <td
                  style="width:62.46%; border-style:solid; border-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:middle; background-color:#d8d8d8"
                >
                  <p
                    style="margin-top:0pt; margin-bottom:0pt; text-align:center; widows:0; orphans:0; font-size:14pt"
                  >
                    <span style="font-family:'仿宋'">名称</span>
                  </p>
                </td>
                <td
                  style="width:37.52%; border-style:solid; border-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:middle; background-color:#d8d8d8"
                >
                  <p
                    style="margin-top:0pt; margin-bottom:0pt; text-align:center; widows:0; orphans:0; font-size:14pt"
                  >
                    <span style="font-family:'仿宋'">价格/万元</span>
                  </p>
                </td>
              </tr>
              <tr style="height:25.5pt">
                <td
                  style="width:62.46%; border-style:solid; border-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:middle"
                >
                  <p
                    style="margin-top:0pt; margin-bottom:0pt; text-align:center; widows:0; orphans:0; font-size:14pt"
                  >
                    <span style="font-family:'仿宋'">计划投入财政资金</span>
                  </p>
                </td>
                <td
                  style="width:37.52%; border-style:solid; border-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:middle"
                >
                  <p
                    style="margin-top:0pt; margin-bottom:0pt; text-align:center; widows:0; orphans:0; font-size:14pt"
                  >
                    <span style="font-family:'仿宋'">398.32</span>
                  </p>
                </td>
              </tr>
              <tr style="height:25.5pt">
                <td
                  style="width:62.46%; border-style:solid; border-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:middle"
                >
                  <p
                    style="margin-top:0pt; margin-bottom:0pt; text-align:center; widows:0; orphans:0; font-size:14pt"
                  >
                    <span style="font-family:'仿宋'">实际到位财政资金</span>
                  </p>
                </td>
                <td
                  style="width:37.52%; border-style:solid; border-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:middle"
                >
                  <p
                    style="margin-top:0pt; margin-bottom:0pt; text-align:center; widows:0; orphans:0; font-size:14pt"
                  >
                    <span style="font-family:'仿宋'">398.32</span>
                  </p>
                </td>
              </tr>
              <tr style="height:25.5pt">
                <td
                  style="width:62.46%; border-style:solid; border-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:middle"
                >
                  <p
                    style="margin-top:0pt; margin-bottom:0pt; text-align:center; widows:0; orphans:0; font-size:14pt"
                  >
                    <span style="font-family:'仿宋'">财政资金到位率</span>
                  </p>
                </td>
                <td
                  style="width:37.52%; border-style:solid; border-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:middle"
                >
                  <p
                    style="margin-top:0pt; margin-bottom:0pt; text-align:center; widows:0; orphans:0; font-size:14pt"
                  >
                    <span style="font-family:'仿宋'">100%</span>
                  </p>
                </td>
              </tr>
            </table>
          </div>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"
          >
            <span style="font-family:Calibri">&#xa0;</span>
          </p>
          <h2
            style="margin-top:13pt; margin-bottom:13pt; text-align:justify; page-break-inside:avoid; page-break-after:avoid; line-height:172%; widows:0; orphans:0; font-size:14pt"
          >
            <a name="_Toc358"
              ><span style="font-family:'仿宋'">（</span
              ><span style="font-family:'仿宋'">二</span
              ><span style="font-family:'仿宋'">）资金实际支出情况</span></a
            >
          </h2>
          <table
            cellspacing="0"
            cellpadding="0"
            style="width:99.94%; border-collapse:collapse"
          >
            <tr style="height:25pt">
              <td
                colspan="2"
                style="border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle; background-color:#d8d8d8"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'; font-weight:bold"
                    >工作项目</span
                  >
                </p>
              </td>
              <td
                style="width:16.92%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle; background-color:#d8d8d8"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'; font-weight:bold"
                    >细分内容</span
                  >
                </p>
              </td>
              <td
                style="width:12.36%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle; background-color:#d8d8d8"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'; font-weight:bold"
                    >实施范围</span
                  >
                </p>
              </td>
              <td
                style="width:11.76%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle; background-color:#d8d8d8"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'; font-weight:bold">数量</span>
                </p>
              </td>
              <td
                style="width:11.76%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle; background-color:#d8d8d8"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'; font-weight:bold">单位</span>
                </p>
              </td>
              <td
                style="width:11.84%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle; background-color:#d8d8d8"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'; font-weight:bold"
                    >单价/元</span
                  >
                </p>
              </td>
              <td
                style="width:11.84%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle; background-color:#d8d8d8"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'; font-weight:bold"
                    >金额/万</span
                  >
                </p>
              </td>
            </tr>
            <tr style="height:25pt">
              <td
                rowspan="2"
                style="width:11.7%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">1.阅读环境调研及规划</span>
                </p>
              </td>
              <td
                style="width:11.76%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">调研</span>
                </p>
              </td>
              <td
                style="width:16.92%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">校园阅读情况摸底调研</span>
                </p>
              </td>
              <td
                style="width:12.36%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">银川示范中小学</span>
                </p>
              </td>
              <td
                style="width:11.76%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">37</span>
                </p>
              </td>
              <td
                style="width:11.76%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">所</span>
                </p>
              </td>
              <td
                style="width:11.84%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">1000</span>
                </p>
              </td>
              <td
                style="width:11.84%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">3.7</span>
                </p>
              </td>
            </tr>
            <tr style="height:25pt">
              <td
                style="width:11.76%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">规划</span>
                </p>
              </td>
              <td
                style="width:16.92%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">示范校阅读环境规划</span>
                </p>
              </td>
              <td
                style="width:12.36%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">银川示范中小学</span>
                </p>
              </td>
              <td
                style="width:11.76%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">37</span>
                </p>
              </td>
              <td
                style="width:11.76%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">所</span>
                </p>
              </td>
              <td
                style="width:11.84%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">2000</span>
                </p>
              </td>
              <td
                style="width:11.84%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">7.4</span>
                </p>
              </td>
            </tr>
            <tr style="height:25pt">
              <td
                rowspan="4"
                style="width:11.7%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">2.分级阅读资源建设</span>
                </p>
              </td>
              <td
                rowspan="2"
                style="width:11.76%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">小学</span>
                </p>
              </td>
              <td
                style="width:16.92%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'"
                    >小学分级阅读书单研发96本</span
                  >
                </p>
              </td>
              <td
                style="width:12.36%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">银川所有小学</span>
                </p>
              </td>
              <td
                style="width:11.76%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">96</span>
                </p>
              </td>
              <td
                style="width:11.76%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">本</span>
                </p>
              </td>
              <td
                style="width:11.84%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">100</span>
                </p>
              </td>
              <td
                style="width:11.84%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">0.96</span>
                </p>
              </td>
            </tr>
            <tr style="height:25pt">
              <td
                style="width:16.92%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'"
                    >小学必读书目题库研发96本</span
                  >
                </p>
              </td>
              <td
                style="width:12.36%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">银川所有小学</span>
                </p>
              </td>
              <td
                style="width:11.76%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">96</span>
                </p>
              </td>
              <td
                style="width:11.76%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">本</span>
                </p>
              </td>
              <td
                style="width:11.84%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">1000</span>
                </p>
              </td>
              <td
                style="width:11.84%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">9.6</span>
                </p>
              </td>
            </tr>
            <tr style="height:25pt">
              <td
                rowspan="2"
                style="width:11.76%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">中学</span>
                </p>
              </td>
              <td
                style="width:16.92%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">中学阅读书单研发20本</span>
                </p>
              </td>
              <td
                style="width:12.36%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">银川所有中学</span>
                </p>
              </td>
              <td
                style="width:11.76%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">20</span>
                </p>
              </td>
              <td
                style="width:11.76%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">本</span>
                </p>
              </td>
              <td
                style="width:11.84%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">300</span>
                </p>
              </td>
              <td
                style="width:11.84%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">0.6</span>
                </p>
              </td>
            </tr>
            <tr style="height:25pt">
              <td
                style="width:16.92%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'"
                    >中学配套20本精读训练题库研发</span
                  >
                </p>
              </td>
              <td
                style="width:12.36%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">银川所有中学</span>
                </p>
              </td>
              <td
                style="width:11.76%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">20</span>
                </p>
              </td>
              <td
                style="width:11.76%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">本</span>
                </p>
              </td>
              <td
                style="width:11.84%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">1750</span>
                </p>
              </td>
              <td
                style="width:11.84%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">3.5</span>
                </p>
              </td>
            </tr>
            <tr style="height:25pt">
              <td
                rowspan="2"
                style="width:11.7%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">3.阅读监测评价服务</span>
                </p>
              </td>
              <td
                style="width:11.76%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">小学</span>
                </p>
              </td>
              <td
                style="width:16.92%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'"
                    >小学校园阅读跟踪测评服务</span
                  >
                </p>
              </td>
              <td
                style="width:12.36%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">银川所有小学生</span>
                </p>
              </td>
              <td
                style="width:11.76%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">7000</span>
                </p>
              </td>
              <td
                style="width:11.76%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">人</span>
                </p>
              </td>
              <td
                style="width:11.84%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">100</span>
                </p>
              </td>
              <td
                style="width:11.84%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">70</span>
                </p>
              </td>
            </tr>
            <tr style="height:25pt">
              <td
                style="width:11.76%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">中学</span>
                </p>
              </td>
              <td
                style="width:16.92%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'"
                    >中学校园阅读跟踪测评服务</span
                  >
                </p>
              </td>
              <td
                style="width:12.36%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">银川所有中学生</span>
                </p>
              </td>
              <td
                style="width:11.76%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">3000</span>
                </p>
              </td>
              <td
                style="width:11.76%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">人</span>
                </p>
              </td>
              <td
                style="width:11.84%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">130</span>
                </p>
              </td>
              <td
                style="width:11.84%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">39</span>
                </p>
              </td>
            </tr>
            <tr style="height:25pt">
              <td
                rowspan="3"
                style="width:11.7%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">4.阅读课程建设</span>
                </p>
              </td>
              <td
                style="width:11.76%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">整本书课程研发</span>
                </p>
              </td>
              <td
                style="width:16.92%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'"
                    >整本书课程研发（含教学设计、教学PPT等，64本书，平均每本书开发4课时）</span
                  >
                </p>
              </td>
              <td
                style="width:12.36%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">银川所有中小学</span>
                </p>
              </td>
              <td
                style="width:11.76%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">256</span>
                </p>
              </td>
              <td
                style="width:11.76%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">课时</span>
                </p>
              </td>
              <td
                style="width:11.84%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">500</span>
                </p>
              </td>
              <td
                style="width:11.84%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">12.8</span>
                </p>
              </td>
            </tr>
            <tr style="height:25pt">
              <td
                style="width:11.76%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">诵读课程</span>
                </p>
              </td>
              <td
                style="width:16.92%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'"
                    >诵读课程研发（含诵读指导课，每单元1课时、诵读欣赏课50课时，共146课时）</span
                  >
                </p>
              </td>
              <td
                style="width:12.36%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">银川所有中小学</span>
                </p>
              </td>
              <td
                style="width:11.76%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">146</span>
                </p>
              </td>
              <td
                style="width:11.76%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">课时</span>
                </p>
              </td>
              <td
                style="width:11.84%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">600</span>
                </p>
              </td>
              <td
                style="width:11.84%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">8.76</span>
                </p>
              </td>
            </tr>
            <tr style="height:25pt">
              <td
                style="width:11.76%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">书法课程</span>
                </p>
              </td>
              <td
                style="width:16.92%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'"
                    >书法课程研发（含粉笔字、硬笔字、软笔字课程，共300课时）</span
                  >
                </p>
              </td>
              <td
                style="width:12.36%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">银川所有中小学</span>
                </p>
              </td>
              <td
                style="width:11.76%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">300</span>
                </p>
              </td>
              <td
                style="width:11.76%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">课时</span>
                </p>
              </td>
              <td
                style="width:11.84%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">500</span>
                </p>
              </td>
              <td
                style="width:11.84%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">15</span>
                </p>
              </td>
            </tr>
            <tr style="height:25pt">
              <td
                rowspan="7"
                style="width:11.7%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">5.项目实施</span>
                </p>
              </td>
              <td
                rowspan="3"
                style="width:11.76%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">智能图书分享系统</span>
                </p>
              </td>
              <td
                style="width:16.92%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'"
                    >智能校园书柜（含1000本图书）</span
                  >
                </p>
              </td>
              <td
                style="width:12.36%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">6所示范学校</span>
                </p>
              </td>
              <td
                style="width:11.76%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">6</span>
                </p>
              </td>
              <td
                style="width:11.76%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">所</span>
                </p>
              </td>
              <td
                style="width:11.84%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">118000</span>
                </p>
              </td>
              <td
                style="width:11.84%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">70.8</span>
                </p>
              </td>
            </tr>
            <tr style="height:25pt">
              <td
                style="width:16.92%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">智能图书分享系统软件</span>
                </p>
              </td>
              <td
                style="width:12.36%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">6所示范学校</span>
                </p>
              </td>
              <td
                style="width:11.76%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">6</span>
                </p>
              </td>
              <td
                style="width:11.76%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">所</span>
                </p>
              </td>
              <td
                style="width:11.84%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">2000</span>
                </p>
              </td>
              <td
                style="width:11.84%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">1.2</span>
                </p>
              </td>
            </tr>
            <tr style="height:25pt">
              <td
                style="width:16.92%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">运输、安装、调试</span>
                </p>
              </td>
              <td
                style="width:12.36%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">6所示范学校</span>
                </p>
              </td>
              <td
                style="width:11.76%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">6</span>
                </p>
              </td>
              <td
                style="width:11.76%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">所</span>
                </p>
              </td>
              <td
                style="width:11.84%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">5000</span>
                </p>
              </td>
              <td
                style="width:11.84%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">3</span>
                </p>
              </td>
            </tr>
            <tr style="height:25pt">
              <td
                rowspan="2"
                style="width:11.76%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">教师培训</span>
                </p>
              </td>
              <td
                style="width:16.92%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'"
                    >现场阅读教学培训会，2次/年</span
                  >
                </p>
              </td>
              <td
                style="width:12.36%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">300名种子教师</span>
                </p>
              </td>
              <td
                style="width:11.76%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">600</span>
                </p>
              </td>
              <td
                style="width:11.76%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">人次</span>
                </p>
              </td>
              <td
                style="width:11.84%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">250</span>
                </p>
              </td>
              <td
                style="width:11.84%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">15</span>
                </p>
              </td>
            </tr>
            <tr style="height:25pt">
              <td
                style="width:16.92%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'"
                    >远程阅读教学培训会，16次/年</span
                  >
                </p>
              </td>
              <td
                style="width:12.36%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">300名种子教师</span>
                </p>
              </td>
              <td
                style="width:11.76%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">4800</span>
                </p>
              </td>
              <td
                style="width:11.76%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">人次</span>
                </p>
              </td>
              <td
                style="width:11.84%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">100</span>
                </p>
              </td>
              <td
                style="width:11.84%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">48</span>
                </p>
              </td>
            </tr>
            <tr style="height:25pt">
              <td
                rowspan="2"
                style="width:11.76%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">阅读活动开展</span>
                </p>
              </td>
              <td
                style="width:16.92%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">线上活动</span>
                </p>
              </td>
              <td
                style="width:12.36%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">银川所有中小学</span>
                </p>
              </td>
              <td
                style="width:11.76%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">10</span>
                </p>
              </td>
              <td
                style="width:11.76%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">次</span>
                </p>
              </td>
              <td
                style="width:11.84%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">15000</span>
                </p>
              </td>
              <td
                style="width:11.84%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">15</span>
                </p>
              </td>
            </tr>
            <tr style="height:25pt">
              <td
                style="width:16.92%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">阅读导读单</span>
                </p>
              </td>
              <td
                style="width:12.36%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">银川所有中小学</span>
                </p>
              </td>
              <td
                style="width:11.76%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">45</span>
                </p>
              </td>
              <td
                style="width:11.76%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">本</span>
                </p>
              </td>
              <td
                style="width:11.84%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">2000</span>
                </p>
              </td>
              <td
                style="width:11.84%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">9</span>
                </p>
              </td>
            </tr>
            <tr style="height:25pt">
              <td
                rowspan="3"
                style="width:11.7%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">6.项目总结</span>
                </p>
              </td>
              <td
                style="width:11.76%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">成果出版</span>
                </p>
              </td>
              <td
                style="width:16.92%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'"
                    >基于校园阅读数据监测和反馈，将实践相关经验进行总结整理，出版发表2本（每本暂定30万字，500页）</span
                  >
                </p>
              </td>
              <td
                style="width:12.36%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">银川所有中小学</span>
                </p>
              </td>
              <td
                style="width:11.76%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">2</span>
                </p>
              </td>
              <td
                style="width:11.76%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">本</span>
                </p>
              </td>
              <td
                style="width:11.84%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">150000</span>
                </p>
              </td>
              <td
                style="width:11.84%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">30</span>
                </p>
              </td>
            </tr>
            <tr style="height:25pt">
              <td
                style="width:11.76%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">论坛</span>
                </p>
              </td>
              <td
                style="width:16.92%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'"
                    >协助银川主办全国性阅读主题学术论坛，1次</span
                  >
                </p>
              </td>
              <td
                style="width:12.36%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">银川所有中小学</span>
                </p>
              </td>
              <td
                style="width:11.76%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">1</span>
                </p>
              </td>
              <td
                style="width:11.76%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">次</span>
                </p>
              </td>
              <td
                style="width:11.84%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">300000</span>
                </p>
              </td>
              <td
                style="width:11.84%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">30</span>
                </p>
              </td>
            </tr>
            <tr style="height:25pt">
              <td
                style="width:11.76%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">白皮书</span>
                </p>
              </td>
              <td
                style="width:16.92%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'"
                    >京银共读白皮书发布，1本</span
                  >
                </p>
              </td>
              <td
                style="width:12.36%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">银川所有中小学</span>
                </p>
              </td>
              <td
                style="width:11.76%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">1</span>
                </p>
              </td>
              <td
                style="width:11.76%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">次</span>
                </p>
              </td>
              <td
                style="width:11.84%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">50000</span>
                </p>
              </td>
              <td
                style="width:11.84%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">5</span>
                </p>
              </td>
            </tr>
            <tr style="height:25pt">
              <td
                colspan="7"
                style="border-top-style:solid; border-top-width:0.75pt; border-left-style:solid; border-left-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-top:0.38pt; padding-right:0.75pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">总计</span>
                </p>
              </td>
              <td
                style="width:11.84%; border-style:solid; border-width:0.75pt; padding-top:0.38pt; padding-right:0.38pt; padding-left:0.38pt; vertical-align:middle"
              >
                <p
                  style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; font-size:12pt"
                >
                  <span style="font-family:'仿宋'">398.32</span>
                </p>
              </td>
            </tr>
          </table>

          <p
            style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"
          >
            <span style="font-family:Calibri">&#xa0;</span>
          </p>
          <h2
            style="margin-top:13pt; margin-bottom:13pt; text-align:justify; page-break-inside:avoid; page-break-after:avoid; line-height:172%; widows:0; orphans:0; font-size:14pt"
          >
            <a name="_Toc14628"
              ><span style="font-family:'仿宋'">三、项目组织实施情况</span></a
            >
          </h2>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; text-align:justify; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'"
              >本项目以构建中小学素养阅读生态区为目标，借助互联网大数据分析评价技术，有效激发学生阅读兴趣、提升学生阅读能力、丰富校园阅读内涵，在传统阅读促进活动基础上拓展创新，突出家校联动的指导作用，强化阅读在中小学课堂教学和课外阅读的互补价值，推动阅读的整体性变革。项目在实施过程中的主要工作如下：</span
            >
          </p>
          <h2
            style="margin-top:13pt; margin-bottom:13pt; text-align:justify; page-break-inside:avoid; page-break-after:avoid; line-height:172%; widows:0; orphans:0; font-size:14pt"
          >
            <a name="_Toc10265"
              ><span style="font-family:'仿宋'">（一）项目统筹规划</span></a
            >
          </h2>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; text-align:justify; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'"
              >首都师范大学联合首都各高校及中小学校组建专家团队，统筹规划整体项目，立足银川中小学师生的实际情况和教学需求，制定了详细的实施方案，并根据实施中的反馈和问题，不断调整优化，以期探索具有银川本地特色的阅读内涵发展模式。</span
            >
          </p>
          <h2
            style="margin-top:13pt; margin-bottom:13pt; text-align:justify; page-break-inside:avoid; page-break-after:avoid; line-height:172%; widows:0; orphans:0; font-size:14pt"
          >
            <a name="_Toc20697"
              ><span style="font-family:'仿宋'">（二）组建项目团队</span></a
            >
          </h2>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; text-align:justify; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'"
              >项目由首都师范大学人工智能教育研究院副院长、双优云桥项目负责人蔡可教授担任首席专家。语文学科方面的教授做顶层设计，北京市区教研员和一线特高级教师亲授指导，同时组建逾十人的项目服务团队，配合专家教师做好协调助教工作。此外，为了做好项目服务，项目组针对40所项目校，分别建立项目群，进行定向支持和服务。教研层面，联合银川当地教研员，秉持因地制宜、因材施教的原则，组织实施项目应用。</span
            >
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-align:center; widows:0; orphans:0; font-size:14pt"
          >
            <img
              src="./img/aej1n-66wvl.018.png"
              width="566"
              height="366"
              alt=""
              style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline"
            />
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-align:center; widows:0; orphans:0; font-size:12pt"
          >
            <span style="font-family:'仿宋'">图：专家团队</span>
          </p>
          <h2
            style="margin-top:13pt; margin-bottom:13pt; text-align:justify; page-break-inside:avoid; page-break-after:avoid; line-height:172%; widows:0; orphans:0; font-size:14pt"
          >
            <a name="_Toc3374"
              ><span style="font-family:'仿宋'">（三）明确研修内容</span></a
            >
          </h2>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; text-align:justify; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'"
              >年初因为疫情原因，项目组及时调整方案，充分利用互联网优势，1月14日率先启动。确定了从前期问卷调研到培养学生阅读习惯、匹配阅读资源、营造阅读环境、指导阅读方法、展示阅读成果六大核心内容，分步实施、环环相扣，切实服务于银川中小学师生。</span
            >
          </p>
          <h2
            style="margin-top:13pt; margin-bottom:13pt; text-align:justify; page-break-inside:avoid; page-break-after:avoid; line-height:172%; widows:0; orphans:0; font-size:14pt"
          >
            <a name="_Toc11815"
              ><span style="font-family:'仿宋'">（四）项目实施推进</span></a
            >
          </h2>
          <h3
            style="margin-top:13pt; margin-bottom:13pt; text-align:justify; page-break-inside:avoid; page-break-after:avoid; line-height:172%; widows:0; orphans:0; font-size:14pt"
          >
            <a name="_Toc7234"
              ><span style="font-family:'仿宋'">1.开展中小学阅读调查</span></a
            >
          </h3>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; text-align:justify; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'"
              >针对银川40所项目校进行调研，重点了解各校的阅读开展情况，教师、学生和家长的阅读现状和需求，以便后期有针对性地制定方案，并开展相关活动。开展两次问卷调研，共回收2044份教师（含管理者）卷、24551份学生卷、28333份家长卷。</span
            >
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-align:center; widows:0; orphans:0; font-size:14pt"
          >
            <img
              src="./img/aej1n-66wvl.019.png"
              width="566"
              height="376"
              alt="问卷调查"
              style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline"
            />
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-align:center; widows:0; orphans:0; font-size:12pt"
          >
            <span style="font-family:'仿宋'">图：调研问卷（部分）</span>
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-align:center; widows:0; orphans:0; font-size:12pt"
          >
            <img
              src="./img/aej1n-66wvl.020.png"
              width="566"
              height="280"
              alt="微信图片_20210114112427"
              style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline"
            />
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-align:center; widows:0; orphans:0; font-size:12pt"
          >
            <span style="font-family:'仿宋'">图：问卷调研情况（部分）</span>
          </p>
          <h3
            style="margin-top:13pt; margin-bottom:13pt; text-align:justify; page-break-inside:avoid; page-break-after:avoid; line-height:172%; widows:0; orphans:0; font-size:14pt"
          >
            <a name="_Toc9737"
              ><span style="font-family:'仿宋'">2.组织开展阅读系列活动</span></a
            >
          </h3>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; text-align:justify; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'"
              >2020年共读工程重点开展7次阅读活动，累计参与人数为500名教师，99705名学生（含一名学生参与多次活动）。覆盖范围广，参与性高，通过活动激发学生阅读兴趣，培养阅读习惯，指导阅读方法。</span
            >
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; text-align:justify; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'"
              >针对银川中小学生的阅读现状，有目的地设计实施“21天阅读活动”、“我为爱书代言”活动和悦读乐写征文活动来培养学生的阅读习惯；组织开展“乐智悦读app任务指南”活动，指导师生有效使用平台听说读写资源，培养学生的信息素养；组织端午节诗歌朗诵和国庆节硬笔书写活动等主题性活动激发学生阅读兴趣；利用暑期组织两期“同读一本书”活动，指导学生阅读方法，沉淀阅读成果。</span
            >
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; text-align:justify; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'"
              >以阅读为活动核心，通过交流与分享，采用问题引导、任务驱动从说、读、写、绘、等形式激发学生的探究兴趣、调动学生的阅读积极性，让学生在阅读中有知识收获，有写、说、绘等多维度的成果输出。共产出539814件具有银川本地特色的学生成果，生成了一批具有银川特色的丰硕成果、丰厚积淀。</span
            >
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:21pt; text-align:center; widows:0; orphans:0; font-size:10.5pt"
          >
            <img
              src="./img/aej1n-66wvl.021.png"
              width="479"
              height="260"
              alt=""
              style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline"
            />
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-align:center; widows:0; orphans:0; font-size:12pt"
          >
            <span style="font-family:'仿宋'">图：阅读活动参与人数</span>
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:21pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"
          >
            <span style="font-family:'仿宋'">&#xa0;</span>
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-align:center; widows:0; orphans:0; font-size:10.5pt"
          >
            <img
              src="./img/aej1n-66wvl.022.png"
              width="566"
              height="292"
              alt="图片3"
              style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline"
            />
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-align:center; widows:0; orphans:0; font-size:12pt"
          >
            <span style="font-family:'仿宋'">图：阅读活动</span>
          </p>
          <h3
            style="margin-top:13pt; margin-bottom:13pt; text-align:justify; page-break-inside:avoid; page-break-after:avoid; line-height:172%; widows:0; orphans:0; font-size:14pt"
          >
            <a name="_Toc30429"
              ><span style="font-family:'仿宋'">3.整合优质阅读资源</span></a
            >
          </h3>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; text-align:justify; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'"
              >针对中小学师生在听说读写方面的学习需求，以新部编版教材为基础，提供多样化优质资源。</span
            >
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; text-align:justify; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'; font-weight:bold">（1）</span
            ><span style="font-family:'仿宋'; font-weight:bold"
              >整本书阅读学习资源</span
            >
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; text-align:justify; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'"
              >综合教育部、新课标及中国小学生基础阅读书目等多维方向，提供近600本全本阅读书目，及其配套精选试题，学生可在线下阅读后，在线上进行测评，以了解自己的阅读效果。</span
            >
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; text-align:justify; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'; font-weight:bold">（2）</span
            ><span style="font-family:'仿宋'; font-weight:bold"
              >单元主题阅读学习资源</span
            >
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; text-align:justify; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'"
              >同步统编语文教材目录，以教材单元为结构，提供1882本匹配单元主题相关的中短篇阅读篇目，并提供在线阅读资源和配套试题，学生边读边测，实时获取阅读报告，了解阅读能力。</span
            >
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; text-align:justify; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'; font-weight:bold">（3）</span
            ><span style="font-family:'仿宋'; font-weight:bold"
              >朗读、诵读及生字词学习等音频资源</span
            >
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; text-align:justify; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'"
              >邀请国家一级播音员录制专业、规范的统编版语文教材中全部课文及所有生字、生词，同时支持学生语音测评。</span
            >
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; text-align:justify; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'; font-weight:bold">（4）</span
            ><span style="font-family:'仿宋'; font-weight:bold"
              >欧阳中石先生团队的书写教学视频资源</span
            >
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; text-align:justify; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'"
              >匹配统编教材要求，生字词的字音、字义、字形演变、字形解析及书写视频等优质课程资源，让学生在认识、理解生字、生词的基础上写好规范字。</span
            >
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-align:center; widows:0; orphans:0; font-size:14pt"
          >
            <img
              src="./img/aej1n-66wvl.023.png"
              width="554"
              height="176"
              alt="图片1"
              style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline"
            />
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-align:center; widows:0; orphans:0; font-size:12pt"
          >
            <span style="font-family:'仿宋'">图：阅读资源示例</span>
          </p>
          <h3
            style="margin-top:13pt; margin-bottom:13pt; text-align:justify; page-break-inside:avoid; page-break-after:avoid; line-height:172%; widows:0; orphans:0; font-size:14pt"
          >
            <a name="_Toc14999"
              ><span style="font-family:'仿宋'">4.建设智能共读分享系统</span></a
            >
          </h3>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; text-align:justify; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'"
              >根据前期项目校参与数据，从银川40所项目校中选择5所小学（银川阅海小学、银川阅海第二小学、银川市中关村小学、金凤区光彩小学、兴庆区大塘中心小学）和1所中学（银川阅海中学），分别配置一台智能共读分享系统，含1000本图书。通过图书共享的理念，方便学生借书，在提高学生阅读兴趣、扩大阅读量的同时，大大减轻家长购书负担。</span
            >
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-align:center; widows:0; orphans:0; font-size:14pt"
          >
            <img
              src="./img/aej1n-66wvl.024.png"
              width="466"
              height="304"
              alt="图片2"
              style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline"
            />
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-align:center; widows:0; orphans:0; font-size:12pt"
          >
            <span style="font-family:'仿宋'">图：智能共读分享系统</span>
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-align:center; widows:0; orphans:0; font-size:12pt"
          >
            <span style="font-family:'仿宋'">&#xa0;</span>
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-align:center; widows:0; orphans:0; font-size:14pt"
          >
            <img
              src="./img/aej1n-66wvl.025.png"
              width="466"
              height="312"
              alt="图片1"
              style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline"
            />
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-align:center; widows:0; orphans:0; font-size:12pt"
          >
            <span style="font-family:'仿宋'"
              >图：学生在使用智能共读分享系统借阅图书</span
            >
          </p>
          <h3
            style="margin-top:13pt; margin-bottom:13pt; text-align:justify; page-break-inside:avoid; page-break-after:avoid; line-height:172%; widows:0; orphans:0; font-size:14pt"
          >
            <a name="_Toc2741"
              ><span style="font-family:'仿宋'">5.组织开展种子教师培养</span></a
            >
          </h3>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; text-align:justify; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'"
              >通过前期“21天阅读活动”的参与情况，在银川项目校中选拔出300位种子教师组织开展培训。采取网络培训、面授培训和特色班集训等形式。</span
            >
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; text-align:justify; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'"
              >组织开展“京银共读中期汇报暨下半年面授培训”和“手拉手
              共成长”京银共读研修活动<span class="font-weight" @click="handle(22)">2</span> 次面授培训，推进项目培训落地与效果深化。414人次项目校负责人及种子教师现场参与，完成2次作业，所提交作业248篇。</span
            >
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; text-align:justify; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'"
              >组织开展<span class="font-weight" @click="handle(23)">12</span> 次线上培训活动，创新培养方式与途径，融合线上课程、微课程等形式，通过案例教学、专题教学、研修互动等方法，培训内容做到理论培训与实践培训有效结合，实现分层培养，构建教师泛在学习环境，促进教师持续提升。活动覆盖2941人次，平均参与率为81.7%，提交作品1989篇。</span
            >
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; text-align:justify; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'"
              >为进一步推动种子教师的个性化发展，对有提升意愿的54位教师进行特色培养，在常态化培训基础上增设培训内容，建立家校联动的阅读推广机制，打造精品阅读展示课例，探索具有银川特色的阅读教学模式，提升中小学师生的阅读素养。邀请小学组、初中组阅读方面的专家组织3次线上集训，共收到16份优质教学设计，组织开展两场“亲子选书”“家长进课堂”活动。</span
            >
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-align:center; widows:0; orphans:0; font-size:14pt"
          >
            <img
              src="./img/aej1n-66wvl.026.png"
              width="282"
              height="332"
              alt="图片2"
              style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline"
            /><img
              src="./img/aej1n-66wvl.027.png"
              width="270"
              height="332"
              alt="图片2"
              style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline"
            />
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-align:center; widows:0; orphans:0; font-size:12pt"
          >
            <span style="font-family:'仿宋'">图：教师培训（部分）</span>
          </p>
          <h3
            style="margin-top:13pt; margin-bottom:13pt; text-align:justify; page-break-inside:avoid; page-break-after:avoid; line-height:172%; widows:0; orphans:0; font-size:14pt"
          >
            <a name="_Toc20136"
              ><span style="font-family:'仿宋'">6.出版阅读成果</span></a
            >
          </h3>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; text-align:justify; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'; font-weight:bold"
              >（1）数据成果</span
            >
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; text-align:justify; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'"
              >每学期末，通过阅读过程中所产生的阅读时间、阅读测评结果等数据的收集，有效地分析学生阅读效果及阅读能力的发展情况，形成2份绩效评估报告。</span
            >
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; text-align:justify; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'; font-weight:bold"
              >（2）案例成果</span
            >
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; text-align:justify; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'"
              >①学生作品：将悦读乐写征文活动的学生成果和优秀作品，组织项目组专家复审、阅读教研专家和首师大教授组三审，首都师范大学出版社编审组复核后，精选了银川市31所项目校241篇优秀学生作品，结集出册。</span
            >
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; text-align:justify; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'"
              >②教师课例：将教师在参加培训过程中的教学思考和课堂案例，分主题、分模块总结整理，并最终出版教师成果集。</span
            >
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; text-align:justify; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'; font-weight:bold"
              >（3）共读白皮书</span
            >
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; text-align:justify; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'"
              >根据本年度项目开展情况和成果，发布一本京银共读白皮书。</span
            >
          </p>
          <h2
            style="margin-top:13pt; margin-bottom:13pt; text-align:justify; page-break-inside:avoid; page-break-after:avoid; line-height:172%; widows:0; orphans:0; font-size:14pt"
          >
            <a name="_Toc4955"
              ><span style="font-family:'仿宋'">（五）制定管理办法</span></a
            >
          </h2>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; text-align:justify; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'"
              >为了有效推进项目，项目组针对项目学校、项目校教师和项目校学生分别制定了详细的积分管理办法，通过数据实时监督学习进度，每月将积分结果同步给参训学员，明确情况，强化管理。此外，项目组通过周计划、月报的形式定期与银川教育局反馈对接，同步项目进度，跟进项目效果。</span
            >
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-align:center; widows:0; orphans:0; font-size:14pt"
          >
            <img
              src="./img/aej1n-66wvl.028.png"
              width="240"
              height="334"
              alt="【管理办法】京银互动一带一路共读工程考核办法_00"
              style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline"
            /><img
              src="./img/aej1n-66wvl.029.png"
              width="240"
              height="334"
              alt="【管理办法】京银互动一带一路共读工程考核办法_01"
              style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline"
            />
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-align:center; widows:0; orphans:0; font-size:12pt"
          >
            <span style="font-family:'仿宋'">图：积分考核办法示例</span>
          </p>
          <h2
            style="margin-top:13pt; margin-bottom:13pt; text-align:justify; page-break-inside:avoid; page-break-after:avoid; line-height:172%; widows:0; orphans:0; font-size:14pt"
          >
            <a name="_Toc19283"
              ><span style="font-family:'仿宋'">四、项目完成情况</span></a
            >
          </h2>
          <h2
            style="margin-top:13pt; margin-bottom:13pt; text-align:justify; page-break-inside:avoid; page-break-after:avoid; line-height:172%; widows:0; orphans:0; font-size:14pt"
          >
            <a name="_Toc10789"
              ><span style="font-family:'仿宋'"
                >（一）覆盖了银川一大批参与项目的一线教师和学生</span
              ></a
            >
          </h2>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; text-align:justify; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'"
              >2020年，共读项目共覆盖银川三区两县一市40所项目校，其中有65604名师生注册APP，阅读104497本书，阅读字数达167亿7532万字，阅读书目类型达12473种。</span
            >
          </p>
          <h2
            style="margin-top:13pt; margin-bottom:13pt; text-align:justify; page-break-inside:avoid; page-break-after:avoid; line-height:172%; widows:0; orphans:0; font-size:14pt"
          >
            <a name="_Toc28332"
              ><span style="font-family:'仿宋'"
                >（二）生成了一系列具有银川市本地教育特色的学生作品</span
              ></a
            >
          </h2>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; text-align:justify; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'"
              >共读工程项目组通过初期调研摸底，针对银川学生的阅读现状，有目的的设计实施各种面向学生的阅读活动，通过线下阅读纸质书，线上提交阅读成果，打破个体阅读的空间局限，形成集体联动，营造共读氛围。活动共有99705人次参与，共产出539814件具有银川本地特色的学生成果，其中241篇优秀学生作品结集出版。</span
            >
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-align:center; widows:0; orphans:0; font-size:12pt"
          >
            <img
              src="./img/aej1n-66wvl.030.png"
              width="504"
              height="306"
              alt="微信图片_20210112153105"
              style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline"
            />
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-align:center; widows:0; orphans:0; font-size:12pt"
          >
            <span style="font-family:'仿宋'"
              >图：悦读乐写征文征文活动作品集</span
            >
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt"
          >
            <span style="font-family:Calibri">&#xa0;</span>
          </p>
          <h2
            style="margin-top:13pt; margin-bottom:13pt; text-align:justify; page-break-inside:avoid; page-break-after:avoid; line-height:172%; widows:0; orphans:0; font-size:14pt"
          >
            <a name="_Toc24207"
              ><span style="font-family:'仿宋'"
                >（三）培养了一支素质优良、结构合理的未来卓越教师队伍</span
              ></a
            >
          </h2>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; text-align:justify; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'"
              >共读项目创新培养方式与途径，融合线上课程、线下面授、微课程等形式，重点培养300名种子教师，通过案例教学、专题教学、研修互动等方式，构建教师泛在学习环境，促进教师持续提升。研修累计覆盖3373人次，平均在线参与率为82.55%。</span
            >
          </p>
          <h2
            style="margin-top:13pt; margin-bottom:13pt; text-align:justify; page-break-inside:avoid; page-break-after:avoid; line-height:172%; widows:0; orphans:0; font-size:14pt"
          >
            <a name="_Toc30000"
              ><span style="font-family:'仿宋'"
                >（四）推进了一套家校共育的实践理念</span
              ></a
            >
          </h2>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; text-align:justify; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'"
              >项目组引入家校共育理念，从教师培训、家长培训、活动指导多维度开展。项目组提供整本书阅读指导手册，供家庭有方法有策略地进行互动阅读，同时教师有针对性地点评，项目组专家再进行指导提升。已组织18380个学生家庭参与进来，提交阅读作品总计99040个。通过学校推选、个人报名、专家评审的方式，组建了一支30人的“家校班”，在专家指导下组织“家长进课堂”和“亲子选书”等活动，拉动家庭与学校进一步配合、融合及支持，形成促进孩子全面发展的合力环境。</span
            >
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-align:center; widows:0; orphans:0; font-size:10.5pt"
          >
            <img
              src="./img/aej1n-66wvl.031.png"
              width="290"
              height="376"
              alt="c16977640cdbf4f01bcb9839911d1da"
              style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline"
            /><img
              src="./img/aej1n-66wvl.032.png"
              width="246"
              height="376"
              alt=""
              style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline"
            />
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-align:center; widows:0; orphans:0; font-size:12pt"
          >
            <span style="font-family:'仿宋'">图：“亲子选书”活动</span>
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-align:center; widows:0; orphans:0; font-size:12pt"
          >
            <span style="font-family:'仿宋'">&#xa0;</span>
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-align:center; widows:0; orphans:0; font-size:10.5pt"
          >
            <img
              src="./img/aej1n-66wvl.033.png"
              width="416"
              height="312"
              alt="ebd70adc4043f0e6699a2bf3bf7ccb5"
              style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline"
            />
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-align:center; widows:0; orphans:0; font-size:12pt"
          >
            <span style="font-family:'仿宋'">图：家长进课堂活动</span>
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-align:center; widows:0; orphans:0; font-size:12pt"
          >
            <span style="font-family:'仿宋'">&#xa0;</span>
          </p>
          <h2
            style="margin-top:13pt; margin-bottom:13pt; text-align:justify; page-break-inside:avoid; page-break-after:avoid; line-height:172%; widows:0; orphans:0; font-size:14pt"
          >
            <a name="_Toc25186"
              ><span style="font-family:'仿宋'"
                >（五）实现“首都带首府、首府带县乡”的辐射互动</span
              ></a
            >
          </h2>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; text-align:justify; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'"
              >共读项目通过组织北京和银川代表学校，开展京银研讨会；组织京银教师和专家代表，开展阅读示范研讨课；组织京银两地学生开展阅读活动等方式，使北京优质教育资源“秒”到银川，实现多地域、零时差的沟通交流，便捷高效促进银川中小学阅读教学的发展。</span
            >
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:24pt; text-align:center; widows:0; orphans:0; font-size:12pt"
          >
            <img
              src="./img/aej1n-66wvl.034.png"
              width="478"
              height="284"
              alt="1608113104(1)"
              style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline"
            />
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-align:center; widows:0; orphans:0; font-size:12pt"
          >
            <span style="font-family:'仿宋'">图：京银互动城乡推广培训活动</span>
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-align:center; widows:0; orphans:0; font-size:12pt"
          >
            <span style="font-family:'仿宋'">&#xa0;</span>
          </p>
          <h2
            style="margin-top:13pt; margin-bottom:13pt; text-align:justify; page-break-inside:avoid; page-break-after:avoid; line-height:172%; widows:0; orphans:0; font-size:14pt"
          >
            <a name="_Toc7095"
              ><span style="font-family:'仿宋'"
                >（六）探索一套基于大数据的互联网+阅读的推广机制</span
              ></a
            >
          </h2>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; text-align:justify; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'"
              >共读项目通过借助互联网大数据分析评价技术，利用“线下阅读，线上评测”的方式，实现阅读效果的可视化，利用“教师领读、师生共读”实现阅读过程的可指导，利用“亲子活动、家校共育”构建全新的阅读生态，成功打造出一套可复制、可推广的互联网阅读模式。</span
            >
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-align:center; page-break-after:avoid; line-height:150%; widows:0; orphans:0; font-size:12pt"
          >
            <img
              src="./img/aej1n-66wvl.035.png"
              width="566"
              height="282"
              alt="微信图片_20210112160944"
              style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline"
            />
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-align:center; widows:0; orphans:0; font-size:12pt"
          >
            <span style="font-family:'仿宋'">图：平台架构图</span>
          </p>
          <h2
            style="margin-top:13pt; margin-bottom:13pt; text-align:justify; page-break-inside:avoid; page-break-after:avoid; line-height:172%; widows:0; orphans:0; font-size:14pt"
          >
            <a name="_Toc28777"
              ><span style="font-family:'仿宋'">五、项目效益</span></a
            >
          </h2>
          <h2
            style="margin-top:13pt; margin-bottom:13pt; text-align:justify; page-break-inside:avoid; page-break-after:avoid; line-height:172%; widows:0; orphans:0; font-size:14pt"
          >
            <a name="_Toc9711"
              ><span style="font-family:'仿宋'">（一）创新教育理念</span></a
            >
          </h2>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; text-align:justify; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'"
              >推动“互联网+阅读”的创新融合，借助互联网大数据分析评价技术，主动完成技术对接，建设数据化跟踪评价平台，保障平台正常运作。通过项目的推动，加强对学校、教师和学生阅读过程的跟进，加强阅读数据采集，及时记录阅读过程性信息，精准掌握中小学生阅读情况，针对性地推动项目的开展和落地。</span
            >
          </p>
          <h2
            style="margin-top:13pt; margin-bottom:13pt; text-align:justify; page-break-inside:avoid; page-break-after:avoid; line-height:172%; widows:0; orphans:0; font-size:14pt"
          >
            <a name="_Toc2429"
              ><span style="font-family:'仿宋'"
                >（二）以阅读为支点促进教育生态变革</span
              ></a
            >
          </h2>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; text-align:justify; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'"
              >教学观念、教学资源、教学结构、教学设施等围绕“阅读”进行重构。重新审视学科教学功能，加强课内教科书阅读指导，加强课外主题阅读指导，促进课外自由阅读；重新定义教研范围，从以教师为主要教研对象向以学生为主要教研对象转移。以阅读为牵引，推动全科阅读、师生共读、亲子共读，形成学生好读书、家长愿读书、教师多读书的局面。将阅读作为一个推送区域基础教育发展的支点，促进教育生态变革。</span
            >
          </p>
          <h2
            style="margin-top:13pt; margin-bottom:13pt; text-align:justify; page-break-inside:avoid; page-break-after:avoid; line-height:172%; widows:0; orphans:0; font-size:14pt"
          >
            <a name="_Toc13733"
              ><span style="font-family:'仿宋'"
                >（三）构建家校学生三位一体的阅读生态</span
              ></a
            >
          </h2>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; text-align:justify; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'"
              >关注中小学生成长中阅读兴趣与习惯的动态变化，注重学校、家庭、社区之间的整体联动，形成中小学生阅读常态化的群体阅读现象。</span
            >
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; text-align:justify; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'"
              >围绕学生阅读主体地位，依托阅读平台，从教师培训、家长培训、活动指导多维度开展阅读生态建设，实现线上线下阅读融合。形成阅读兴趣激发、环境搭建、氛围协调、阅读资源辅助等一系列共识，构造出家校学生三位一体的阅读环境。实现家校主动沟通配合、学生主动读的良性循环阅读生态，推动阅读生态建设往深里走、往实里走。</span
            >
          </p>
          <h2
            style="margin-top:13pt; margin-bottom:13pt; text-align:justify; page-break-inside:avoid; page-break-after:avoid; line-height:172%; widows:0; orphans:0; font-size:14pt"
          >
            <a name="_Toc17190"
              ><span style="font-family:'仿宋'"
                >（四）加强区域联动，辐射引领发展</span
              ></a
            >
          </h2>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; text-align:justify; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'"
              >充分运用互联网、人工智能等先进的信息化技术，打破空间与地域差异，改善教育供给，形成了新型、标准化、可复制的经验。打造首都与首府的桥梁，形成城市带乡村的新格局，引入资源、名师、课程等，打造线上线下教育新体系，辐射引领周边，协同教育发展。</span
            >
          </p>
          <h2
            style="margin-top:13pt; margin-bottom:13pt; text-align:justify; page-break-inside:avoid; page-break-after:avoid; line-height:172%; widows:0; orphans:0; font-size:14pt"
          >
            <a name="_Toc24940"
              ><span style="font-family:'仿宋'"
                >（五）项目培训满意度反馈</span
              ></a
            ><span style="font-family:'仿宋'"> </span>
          </h2>
          <h3
            style="margin-top:13pt; margin-bottom:13pt; text-align:justify; page-break-inside:avoid; page-break-after:avoid; line-height:172%; widows:0; orphans:0; font-size:14pt"
          >
            <a name="_Toc31789"
              ><span style="font-family:'仿宋'">1.项目整体满意度分析</span></a
            >
          </h3>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; text-align:justify; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'"
              >京银互动一带一路共读工程，以构建中小学素养阅读生态区为目标，借助互联网大数据分析评价技术，有效激发学生阅读兴趣、提升学生阅读能力、丰富校园阅读内涵，在传统阅读促进活动基础上拓展创新，突出家校联动的指导作用，强化阅读在中小学课堂教学和课外阅读的互补价值，推动阅读的整体性变革。</span
            >
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; text-align:justify; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'"
              >通过对教师提交的问卷进行分析，对项目各项满意的老师达到了92.51%，老师们对项目给与高度认可。同时教师关于是否持续参加项目培训的反馈表明：86.63%的教师表示“如果有机会，希望持续参与此项目”</span
            >
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; widows:0; orphans:0; font-size:10.5pt"
          >
            <img
              src="./img/aej1n-66wvl.036.png"
              width="601"
              height="300"
              alt=""
              style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline"
            />
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-align:center; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'">图：项目整体满意度</span>
          </p>
          <h3
            style="margin-top:13pt; margin-bottom:13pt; text-align:justify; page-break-inside:avoid; page-break-after:avoid; line-height:172%; widows:0; orphans:0; font-size:14pt"
          >
            <a name="_Toc11616"
              ><span style="font-family:'仿宋'"
                >2.项目方案实施满意度分析</span
              ></a
            >
          </h3>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; text-align:justify; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'"
              >针对项目的课程设置的切适性、专家团队的配备、活动的设计以及培训效果方面进行调查统计，98.4%的老师表示满意，说明项目课程和内容设计很贴合参训教师的实际需求并从中获得收获，得到了绝大多数老师的认可。</span
            >
          </p>
          <h3
            style="margin-top:13pt; margin-bottom:13pt; text-align:justify; page-break-inside:avoid; page-break-after:avoid; line-height:172%; widows:0; orphans:0; font-size:14pt"
          >
            <a name="_Toc11983"
              ><span style="font-family:'仿宋'"
                >3.项目策略方法满意度分析</span
              ></a
            >
          </h3>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; text-align:justify; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'"
              >针对项目实施过程、组织流程、项目管理方式方法和项目培训平台等方案进行调查统计，90.91%的老师表示满意。项目实施方法和策略符合参训教师的核心需求，参训教师能够挖掘更多信息、探索更多路径，切实保证培训质量。</span
            >
          </p>
          <h3
            style="margin-top:13pt; margin-bottom:13pt; text-align:justify; page-break-inside:avoid; page-break-after:avoid; line-height:172%; widows:0; orphans:0; font-size:14pt"
          >
            <a name="_Toc24343"
              ><span style="font-family:'仿宋'"
                >4.项目组织管理满意度分析</span
              ></a
            >
          </h3>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; text-align:justify; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'"
              >针对班级组织与活动安排、管理制度建设等方面进行调查统计，94.12%的老师表示满意，说明绝大多数人员非常认可项目组工作人员的工作态度，并给予了极大肯定。</span
            >
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:150%; widows:0; orphans:0; font-size:10.5pt"
          >
            <img
              src="./img/aej1n-66wvl.037.jpeg"
              width="554"
              height="328"
              alt="C:\Users\ADMINI~1\AppData\Local\Temp\WeChat Files\36414386ec2d38bbad6ae60e8f5b585.jpg"
              style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline"
            />
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-align:center; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'">图：项目各模块满意度</span>
          </p>
          <h2
            style="margin-top:13pt; margin-bottom:13pt; text-align:justify; page-break-inside:avoid; page-break-after:avoid; line-height:172%; widows:0; orphans:0; font-size:14pt"
          >
            <a name="_Toc5489"
              ><span style="font-family:'仿宋'">（六）项目培训感言</span></a
            ><span style="font-family:'仿宋'"> </span>
          </h2>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; text-align:justify; line-height:150%; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'">为期近一年的</span
            ><a name="_Hlk61989852"
              ><span style="font-family:'仿宋'"
                >京银共读项目种子教师培训</span
              ></a
            ><span style="font-family:'仿宋'"
              >圆满结束，回顾培训历程，真是收获满满，令人不得不感叹“双优云桥·共读工程”项目真的是造福了广大教师，尤其是对于青年教师来说，每一场讲座都是一场听觉盛筵，收获满满。</span
            ><span style="font-family:'仿宋'"
              >参与此次种子教师培训，让我印象最深的一点还是能够有幸加入家校班，接受专家老师一对一的指导，更好的服务孩子们。尤其是组织学生和家长进行“你选书，我陪伴，童阅读”的亲子选书活动，家长和孩子都受益匪浅，感触颇深。而在每一次活动的开展中，我也在不断反思，作为一名语文教师，深知阅读的重要性，而现在的课程标准也要求老师依托语文教材，以教材为引子，指导学生去读整本的书。</span
            >
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; text-align:right; line-height:150%; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'; font-weight:bold"
              >银川阅海小学 马佳</span
            >
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; text-align:justify; line-height:150%; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'">&#xa0;</span>
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; text-align:justify; line-height:150%; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'"
              >很感谢“京银共读项目”给了我一个这么好的机会，让我可以把我关于整本书阅读的一些设想，通过自己的公开课给大家呈现出来。在自己筹备这堂课的过程中，往昔关于整本书阅读培训的一幕幕像放电影似的又重现在眼前，曾经给予培训的每位专家的谆谆教导指引着我的设计思路。回家后又把自己今天上的课重新观看了一遍，内心依然不停地泛起层层涟漪，耳边不停地回想着专家给我真切的点评。走在学习的道路上，有这么多前辈领着，真幸福！</span
            >
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; text-align:right; line-height:150%; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'; font-weight:bold"
              >银川市兴庆区回民第一小学  周静</span
            >
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; text-align:justify; line-height:150%; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'">&#xa0;</span>
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; text-align:justify; line-height:150%; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'"
              >通过共读工程培训活动，我发现孩子对阅读有了更浓厚的兴趣，活动伊始参加的孩子并不是很多，随着活动的深入，班里的学生参与率就发生了很大改变，由刚开始的个别学生到现在的大部分学生的加入，学生的阅读能力也较以前有了很大的提升，孩子们也掌握了一些新的阅读方法，这让我对阅读教学也有了信心，尤其是对农村地区孩子能有这样的转变确实不易。</span
            >
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; text-align:right; line-height:150%; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'; font-weight:bold"
              >金贵镇银光小学  马静</span
            >
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; text-align:justify; line-height:150%; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'">&#xa0;</span>
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; text-align:justify; line-height:150%; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'"
              >感谢“京银共读种子教师”培训项目，在这种打破时空限制的培训下，我与各位老师一起学习，共同成长。我们相聚线上参与16次主题学习，我们欢聚线下开展两次面授培训。专家老师的报告有独特的思想，专业性强，对我的专业成长促进很大。以前我在导读设计环节往往是按部就班，缺乏新意，视野狭窄。听了专家老师的报告，拓宽了我的阅读设计的思路，并在实践中得到了检验与改进。</span
            >
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; text-align:right; line-height:150%; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'; font-weight:bold"
              >银川市中关村小学  王媛琴</span
            >
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; text-align:justify; line-height:150%; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'">&#xa0;</span>
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; text-align:justify; line-height:150%; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'"
              >“京银互动一带一路共读工程”是一项系统而宏大的工程，项目组制定了非常完善的培训计划，历时半年的培训学习期间，我们总共进行了16次主题培训，每次培训的专家都紧紧围绕“阅读”，为我们一线的语文教师点亮阅读的心灯，在这个过程中，我转变了思维，改变了做法，更新了关于阅读教学的理念。我很感慨：专家的指引是那么无私；我很感谢：在有限的教学生涯里获得继续学习和提升的机会；我很感恩：银川市成千上万的语文老师里，我成为300名种子教师中的一员。</span
            >
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; text-align:right; line-height:150%; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'; font-weight:bold"
              >银川市西夏区第十小学  康燕</span
            >
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; text-align:justify; line-height:150%; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'">&#xa0;</span>
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; text-align:justify; line-height:150%; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'"
              >非常感谢京银互动一带一路共读工程为我们搭建的优秀桥梁，让我这个乡村青年教师有机会能站在一个大平台上为北京专家和银川教师分享我的授课。也让我们乡村教师能够有机会与北京专家、银川城区教师展开互动与交流。这次共读活动使我受益匪浅，作为农村学校来说，我们接收到的资源有限，在开展整本书阅读教学中往往会遇到一些难题。此次专家培训、送教下乡、城乡互动，则给我们乡村教师带来了福利，带来了启发，带来了收获，使我们获得了坚强的资源后盾，增强了我们的自信心与干劲。</span
            >
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; text-align:right; line-height:150%; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'; font-weight:bold"
              >银川市兴庆区大塘中心小学  王璐瑶</span
            >
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; text-align:justify; line-height:150%; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'">&#xa0;</span>
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; text-align:justify; line-height:150%; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'"
              >从2020年6月11日起到现在，我们相聚线上，参与16次主题学习，欢聚线下开展两次面授培训。学思结合，一起学习，共同成长，在这半年的培训时间里，我收获很多，感悟也很多</span
            ><span style="font-family:'仿宋'"
              >。特别是南京市第十三中学的曹勇军老师的讲座《一间神奇的读书教室》，让我感受非常深刻。曹老师从他开始的经典阅读课程的经历、做法以及他的反思谈起，让我受益匪浅。从阅读小组到读书课程，以及创立阅读小组的一些切实可行的实施办法，都给了我一些启发和帮助。再次感谢培训老师和组织老师的一路陪伴，我将继续努力，将阅读进行到底。</span
            >
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; text-align:right; line-height:150%; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'; font-weight:bold"
              >银川市实验小学</span
            ><span style="font-family:'仿宋'; font-weight:bold"> </span
            ><span style="font-family:'仿宋'; font-weight:bold">吴筱</span>
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; text-align:justify; line-height:150%; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'">&#xa0;</span>
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; text-align:justify; line-height:150%; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'"
              >在为期近一年的学习中，北京项目组科学合理的安排各科课程，让老师们能在上课之余聆听来自北京的前沿教学成果分享和教学理念，我们密切跟进课堂，学习教学方法，倾听各位专家老师们的平准培训和讲座，让我们收获了别样的教学知识和方法，为本校的教学发展提供更好的平台，为自己的课堂注入更多的新鲜思想。另外，这次疫情更是加速改变我们的教学形式，使得我们切身体会到教师对于新的教学理念的迫切需求。我们课堂和教学需要便捷的信息技术，更好服务于我们的课堂。我们很感恩有这样的机会学习，希望通过这样高端的培训，学有所获。唯有努力学习，不断充实，才不辜负这样一次雪中送炭的教师教学培训。</span
            >
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; text-align:right; line-height:150%; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'; font-weight:bold"
              >银川阅海小学  王童谣</span
            >
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; text-align:justify; line-height:150%; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'">&#xa0;</span>
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; text-align:justify; line-height:150%; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'"
              >2020年6月11日，一个新的起点，40所中小学校，300位种子教师，因“京银互动一带一路共读工程”，打破时空的限制，一起学习，共同成长。我们参加了16次线上主题培训，2次线下面授学习，每次培训学习对一名年轻教师的我来说都是非常宝贵的。经过培训学习，真的让我受益匪浅。使我在主题阅读方面有了明确的方向。更加深刻的认识到在以后教学中如何扎实有效的进行阅读活动。
            </span>
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; text-align:right; line-height:150%; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'; font-weight:bold"
              >银川市西夏区回民小学  姚彩</span
            >
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; text-align:justify; line-height:150%; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'">&#xa0;</span>
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; text-align:justify; line-height:150%; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'"
              >京银共读项目活动培训，给我既有的观念一个很强的冲击，使我在理论上也得到了提高，在教学技艺中得到了增长。更重要的是，通过培训，我更加明确了自己在以后的教学中应该如何运用教学方法，更好的上好每一节课。特别是过去不知道如何进行整本书的阅读，现在已经在慢慢的尝试、摸索中，知道如何进行了。随着培训学习的深入，我发现内容更丰富，形式更加多样化。这些培训内容更能给一线教师提供好的教学方法，如何在课堂上突破重难点。让我从别人的课堂教学中感受到了，我应该学什么，反思自己的什么，问题出现在哪里等。</span
            >
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; text-align:right; line-height:150%; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'; font-weight:bold"
              >灵武市东塔学校  张凤</span
            >
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; text-align:justify; line-height:150%; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'">&#xa0;</span>
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; text-align:justify; line-height:150%; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'"
              >这一年，非常庆幸自己能成为京银共读项目种子教师中的一员，跟着专家老师们一起学习整本书阅读与实践课程，为阅读教学打开了一片蓝天。回望这一段时光，感触很多，收获满满，忙碌着，感动着，也喜悦着。众多专家大咖的讲座异彩纷呈，每每坐在电脑前等待上课的时光，就像等待着一场丰盛的文化大餐，满心激动。基于对阅读教学的热爱，想把阅读的喜好传递给我所教的每一位学生，我总是端坐在书桌前，拿出笔记本，认真的记录下每一位老师的教学内容。大半年的学习，似乎成了一种习惯，如同吃饭、喝水一样，成了生活中不可或缺的一部分。不知不觉中，我们参与了16次的线上主题学习，两次面授培训。每次培训都饱含着老师们的心血与智，每本一场培训都给我们打开了一扇窗，让我们看到了阅读教学的别开生面，也不断地反思自己在教学中存在的问题。</span
            >
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; text-align:right; line-height:150%; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'; font-weight:bold"
              >银川唐徕回中南校区  仇菁</span
            >
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; text-align:right; line-height:150%; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'">&#xa0;</span>
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; text-align:justify; line-height:150%; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'"
              >从2020年6月11日起京银共读项目“校长研讨”一直到最后的培训，无论是宁夏本土的老师，还是北京名师，他们热情开明、平易近人，他们兢兢业业、严谨治学的，无不深深影响着我。由于这些老师从事职业教育多年，而且也真正把职业教育当成一份事业去做，所以他们不断探索、创新，总结出一套属于自己却受益他人的理论，这些理论，或深刻，或睿智，或沉稳，或思辨，无不滋润着我的心田，能有幸听到，使我的教育教学观念得到更新。他们对整本书阅读的深入研究，对语文阅读教学的研究总能给我们这些语文教学上的摸索着指出一条明路。</span
            >
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; text-align:right; line-height:150%; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'; font-weight:bold"
              >银川滨河新区景城一中</span
            ><span style="font-family:'仿宋'; font-weight:bold"> </span
            ><span style="font-family:'仿宋'; font-weight:bold">王静</span>
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; text-align:justify; line-height:150%; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'">&#xa0;</span>
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; text-align:justify; line-height:150%; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'"
              >2020年，是不寻常的一年，一场突如其来的疫情打破了我们的生活，也改变着我们的学习方式。我有幸成为“共读工程”项目种子教师中的一员，借助网络平台，一路学习一路前进。基于学情，项目组采用线上主题培训的方式，结合线下面授培训，使我们能够近距离地学习专家先进的教学理念，共享优质的教育资源。在为期近一年的学习中，项目组科学合理地安排各科课程，让我们能聆听前沿教学成果分享和教学理念。我们密切跟进课堂，学习教学方法，倾听各位专家老师们的精准培训和讲座，让我们收获了别样的教学知识和方法，为自己的课堂注入更多的新鲜思想。对我来说，这次培训来得及时，我觉得受益匪浅，深受启迪。</span
            >
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; text-align:right; line-height:150%; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'; font-weight:bold"
              >银川阅海小学  郭红梅</span
            >
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; text-align:justify; line-height:150%; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'">&#xa0;</span>
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; text-align:justify; line-height:150%; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'"
              >初遇共读，一切都让人觉着可爱，秋往冬来，和共读工程一起度过了一个忙碌又充实的阶段，更是几多收获几多不舍。这学期里虽然和北京的老师从未真正相遇，但也从未分离，这一学期里，感受到了北京老师先进的教学理念，更在如何当好一个老师的问题上不断思考、探索、实践、总结。</span
            >
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; text-align:right; line-height:150%; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'; font-weight:bold"
              >银川市实验小学  高丽娜</span
            >
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; text-align:justify; line-height:150%; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'">&#xa0;</span>
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; text-align:justify; line-height:150%; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'"
              >通过参加“京银互助”教师的培训，我更进一步掌握了新课改的发展方向和目标，提高了教育教学的理论水平。通过培训使我对今后的教育教学工作充满信心，在彼此的研究中、讨论中，提高了教学的方法的掌握，使我的业务能力也有了进一步的提高。</span
            >
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; text-align:right; line-height:150%; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'; font-weight:bold"
              >银川市阅海第三小学  漆玉娇</span
            >
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; text-align:justify; line-height:150%; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'">&#xa0;</span>
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; text-align:justify; line-height:150%; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'"
              >我有幸参加了“京银共读项目种子培训”活动，在此期间，共参与了16次主题学习和2次面授活动，聆听了多位专家的精彩讲座，观看了京、银多位优秀教师的阅读示范课，我进一步了解和掌握了关于阅读教学的理念和方法，也反思了自己教学中的不足，真是受益匪浅。</span
            >
          </p>
          <p
            style="margin-top:0pt; margin-bottom:0pt; text-indent:28pt; text-align:right; line-height:150%; widows:0; orphans:0; font-size:14pt"
          >
            <span style="font-family:'仿宋'; font-weight:bold"
              >银川市阅海第二小学 杨亚梅</span
            >
          </p>
          
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  watch: {},
  methods: {
    handle(groupId) {
      this.$router.push({
        path: "/resources",
        // query: { type: "yin",groupId:groupId },
      });
    },
  },
  mounted() {},
  components: {},
};
</script>

<style lang="less" scoped>
.jingyin-center-preview {
  min-width: 1100px;
  padding: 0;
  background: #fff;
  .tabs-box {
    p {
      font-family: Source Han Sans CN !important;
    }
    span {
      font-family: Source Han Sans CN !important;
    }
    display: flex;
    justify-content: space-between;
    // height: 80vh;
    width: 1200px;
    margin: 0 auto;

    .tabs-left {
      border: 1px solid #f8f8f8;
      // box-shadow: 0px 5px 29px 0px rgb(22 115 255 / 16%);
      border-radius: 2px;
      width: 200px;
      padding: 20px 40px;
      font-size: 14px;
      height: 80vh;
      overflow-y: auto;
      background: #fafafa;
    }
    .tabs-right {
      width: 100%;
      padding: 20px 40px;
      margin-left: 20px;
      background: #fafafa;
      flex: 1;
      border: 1px solid #f8f8f8;
      border-radius: 2px;
      height: 80vh;
      overflow-y: auto;
      // width: 200px;
      .font-weight {
        font-family: 仿宋;
        color: red;
        font-weight: bold;
        font-size: 16pt;
        cursor: pointer;
      }
    }
  }
}
</style>
